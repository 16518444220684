@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700,900");
@import url("https://fonts.googleapis.com/css?family=Noto+Serif");
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '@angular/cdk/overlay-prebuilt.css';
:root {
  --accordion-border__bottom: var(--gray--border);
}

:root {
  --avatar-background: var(--gray--border);
}

:root {
  --badge-background: var(--white);
  --badge-border__color: var(--gray--border);
  --badge-border__width: 1px;
  --badge-border__radius: 0.25rem;
  --badge-default__color: var(--gray--border);
}

:root {
  --banner-default__radius: 0.25rem;
  --banner-pill__radius: 1.5rem;
}

:root {
  --btn-size__min-width: 6.5rem;
  --btn-size__height: 2.5rem;
  --btn-size__padding--x: 1.5rem;
  --btn-border__radius--top--left: 0.25rem;
  --btn-border__radius--top--right: 0.25rem;
  --btn-border__radius--bottom--right: 0.25rem;
  --btn-border__radius--bottom--left: 0.25rem;
  --btn-border__width: 1px;
  --btn-disabled__text: #5d6c86;
  --btn-disabled__background: var(--gray--border);
  --btn-disabled__border: var(--gray--border);
  --btn-disabled__icon: var(--text--2);
  --btn-focus__color: var(--secondary--3);
  --btn-focus__width: 4px;
  --btn-font__size: 1rem;
  --btn-font__weight: 800;
  --btn-font__line-height: 1.5rem;
  --btn-special__background--resting: var(--secondary--3);
  --btn-special__background--hover: var(--text--1);
  --btn-special__border--resting: var(--secondary--3);
  --btn-special__border--hover: var(--text--1);
  --btn-special__icon--resting: var(--text--1);
  --btn-special__icon--hover: var(--white);
  --btn-special__text--resting: var(--text--1);
  --btn-special__text--hover: var(--white);
  --btn-high__background--resting: var(--primary);
  --btn-high__background--hover: var(--text--1);
  --btn-high__border--resting: var(--primary);
  --btn-high__border--hover: var(--text--1);
  --btn-high__icon--resting: var(--white);
  --btn-high__icon--hover: var(--white);
  --btn-high__text--resting: var(--white);
  --btn-high__text--hover: var(--white);
  --btn-medium__background--resting: var(--transparent);
  --btn-medium__background--hover: var(--primary--tint);
  --btn-medium__border--resting: var(--primary);
  --btn-medium__border--hover: var(--primary);
  --btn-medium__icon--resting: var(--primary);
  --btn-medium__icon--hover: var(--primary);
  --btn-medium__text--resting: var(--primary);
  --btn-medium__text--hover: var(--primary);
  --btn-low__background--resting: var(--transparent);
  --btn-low__background--hover: var(--transparent);
  --btn-low__border--resting: var(--gray--shadow);
  --btn-low__border--hover: var(--text--2);
  --btn-low__icon--resting: var(--text--2);
  --btn-low__icon--hover: var(--text--2);
  --btn-low__text--resting: var(--text--2);
  --btn-low__text--hover: var(--text--2);
  --btn-text__icon--resting: var(--text--1);
  --btn-text__icon--hover: var(--text--1);
  --btn-text__text--resting: var(--text--1);
  --btn-text__text--hover: var(--text--1);
}

:root {
  --card-background: var(--white);
  --card-border-radius: 0.25rem;
  --card-box-shadow: var(--card-shadow);
}

:root {
  --chip-default__background: var(--gray--border);
  --chip-default__text: var(--white);
  --chip-border__radius: 0.25rem;
}

:root {
  --white: #fff;
  --black: #000;
  --transparent: rgba(255, 255, 255, 0);
  --gray--artboard: #fdfdfd;
  --gray--inactive: #f8f9fa;
  --gray--border: #eceef0;
  --gray--shadow: #c1c8d5;
  --gray--jet: #3f3e3e;
  --mask: rgba(15, 14, 14, 0.8);
  --primary: #2567ed;
  --primary--highlight: #1a469f;
  --primary--tint: #f4f8ff;
  --text--1: #0c2250;
  --text--2: #667592;
  --link: #2567ed;
  --success: #1dc291;
  --success--tint: #f4fcf9;
  --warn: #fdca40;
  --error: #e10b30;
  --error--tint: #fef3f5;
  --secondary--1: #e02271;
  --secondary--2: #fd6240;
  --secondary--3: #fdca40;
  --secondary--4: #2bcba7;
  --secondary--5: #6fd5ee;
  --secondary--6: #446ec2;
  --universal-shopping-footer-content-link-color: #2567ed;
}

::ng-deep {
  /* Customization for dropdown caret color */
}
::ng-deep .dropdown__chevron .icon {
  filter: var(--dropdown__chevron-color-filter, unset);
}

:root {
  --thin: 100;
  --extra-light: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  --extra-bold: 800;
  --heavy: 900;
}

:root {
  --fc-height: 2.5rem;
  --fc-padding-y: 0.5rem;
  --fc-padding-x: 1rem;
  --fc-font-size: 1rem;
  --fc-line-height: 1.5rem;
  --fc-background: var(--white);
  --fc-border-color: var(--gray--shadow);
  --fc-border-width: 1px;
  --fc-border-radius: 0.25rem;
  --fc-hover-background: var(--white);
  --fc-hover-border: var(--primary);
  --fc-focus-background: var(--white);
  --fc-focus-border: var(--primary);
  --fc-active-background: var(--white);
  --fc-active-border: var(--primary);
  --fc-disabled-text: var(--text--1);
  --fc-disabled-icon: var(--text--1);
  --fc-disabled-background: var(--gray--border);
  --fc-disabled-border: var(--gray--border);
  --fc-error-background: var(--transparent);
  --fc-error-border: var(--error);
  --fc-dropdown-triangle: var(--text--1);
  --fc-checkbox-radio-width: 1.25rem;
  --fc-checkbox-radio-height: 1.25rem;
  --fc-checkbox-radio-border-width: 2px;
  --fc-checkbox-radio-border-color: var(--text--1);
  --fc-checkbox-radio-disabled-background: var(--gray--shadow);
  --fc-checkbox-checked-border: var(--primary);
  --fc-checkbox-checked-background: var(--white);
  --fc-checkbox-checked-color: var(--primary);
  --fc-radio-checked-border: var(--primary);
  --fc-radio-checked-background: var(--white);
  --fc-radio-checked-color: var(--primary);
  --fc-radio-checked-height: 0.5rem;
  --fc-radio-checked-width: 0.5rem;
  --fc-menu-height: 12.5rem;
  --fc-menu-background: var(--white);
  --fc-menu-border-radius: 0.25rem;
  --fc-menu-option-hover: var(--gray--inactive);
  --fc-menu-option-highlight: var(--primary);
  --fc-textarea-height: 10rem;
  --fc-icon-color: var(--text--2);
}

::ng-deep .form-control {
  color: var(--form-control-color, var(--text--1, unset));
}

:root {
  --fg-header-border-color: var(--gray--border);
  --fg-header-border-width: 1px;
  --fg-section-border-color: var(--gray--border);
  --fg-section-border-width: 1px;
}

@font-face {
  font-family: "System Icons";
  font-weight: var(--regular);
  font-style: normal;
  src: url("https://zipari-assets-dev.s3.us-east-2.amazonaws.com/democare/6/icons/ZipariIcons.woff") format("woff");
}
@font-face {
  font-family: "Product Icons";
  font-weight: var(--regular);
  font-style: normal;
  src: url("https://s3.amazonaws.com/zipari-product-demos/icons/product-icons.woff") format("woff");
}
:root {
  --icon-color: var(--text--1);
  --icon-disabled-opacity: 0.5;
  --icon-system-small: 1.125rem;
  --icon-system-medium: 1.5rem;
  --icon-system-large: 3rem;
  --icon-product-small: 1.5rem;
  --icon-product-medium: 1.875rem;
  --icon-product-large: 3rem;
}

:root {
  --logo--light: url(https://d32ul9oyxvd2n5.cloudfront.net/assets/logo.png);
  --logo--dark: url(https://d32ul9oyxvd2n5.cloudfront.net/assets/logo.svg);
  --logo--height: 35px;
  --logo--width: 115px;
}

:root {
  --modal-background: var(--white);
  --modal-header-background: var(--gray--inactive);
  --modal-message-background: var(--gray--inactive);
  --modal-border-radius: 0.25rem;
  --modal-footer-border-color: var(--gray--border);
  --modal-footer-border-width: 2px;
  --modal-panel-header-background: var(--primary--highlight);
  --modal-panel-header-text: var(--white);
  --modal-flyout-width: 31.5rem;
  --modal-popup-width: 27rem;
  --modal-large-width: 38.625rem;
}

:root {
  --pb-height: 0.75rem;
  --pb-total-background: var(--gray--border);
  --pb-partial-background: var(--success);
}

:root {
  --switch-width: 5.625rem;
  --switch-height: 2.5rem;
  --switch-border-radius: 1.25rem;
  --switch-inactive-background: var(--gray--border);
  --switch-active-background: var(--success);
  --switch-toggle-radius: 1.25rem;
  --switch-toggle-gap: 0.25rem;
  --switch-toggle-size: 2rem;
  --switch-toggle-inactive-background: var(--text--2);
  --switch-toggle-active-background: var(--white);
  --switch-small-width: 2.8125rem;
  --switch-small-height: 1.25rem;
  --switch-small-toggle-size: 1rem;
  --switch-small-toggle-gap: 0.125rem;
}

:root {
  --table-background: var(--white);
  --table-focus-width: 1px;
  --table-focus-color: var(--gray--shadow);
  --table-actions-mobile-toggle-background: var(--secondary--1);
  --table-actions-mobile-toggle-icon: var(--white);
  --table-actions-mobile-menu-background: var(--white);
  --table-page-button-background: var(--white);
  --table-page-button-border-width: 1px;
  --table-page-button-border-color: var(--gray--border);
  --table-page-button-border-radius: 0.25rem;
  --table-page-button-active: var(--primary);
  --table-body-border-width: 1px;
  --table-body-border-color: var(--gray--border);
  --table-body-border-radius: 0.25rem;
  --table-header-background: var(--gray--border);
  --table-header-border-width: 1px;
  --table-header-border-color: var(--gray--border);
  --table-header-border-radius: 0.25rem;
  --table-header-alt-background: var(--text--1);
  --table-header-alt-border-color: var(--text--1);
  --table-header-alt-text-color: var(--white);
  --table-row-border-width: 1px;
  --table-row-border-color: var(--gray--border);
  --table-row-stripe-color: var(--white);
  --table-cell-padding-x: 0.75rem;
  --table-cell-padding-y: 0.5rem;
}

:root {
  --tabs-header-border-width: 1px;
  --tabs-header-border-color: var(--gray--shadow);
  --tabs-indicator-border-width: 3px;
  --tabs-indicator-border-color: var(--transparent);
  --tabs-inactive-text-color: var(--text--2);
  --tabs-active-border-color: var(--primary);
  --tabs-active-text-color: var(--primary);
  --tabs-active-font-weight: var(--heavy);
}

:root {
  --toggle-width-mobile: 6.25rem;
  --toggle-width-tablet: 7rem;
  --toggle-height: 2.5rem;
  --toggle-border-width: 1px;
  --toggle-border-color: var(--gray--shadow);
  --toggle-border-radius: 0.25rem;
  --toggle-font-size: 1rem;
  --toggle-line-height: 1.5rem;
  --toggle-active-background: var(--text--1);
  --toggle-active-border: var(--text--1);
  --toggle-active-text-color: var(--white);
  --toggle-active-font-weight: var(--heavy);
  --toggle-disabled-text-color: var(--gray--shadow);
  --toggle-disabled-active-background: var(--gray--border);
  --toggle-disabled-active-text-color: var(--black);
  --toggle-disabled-active-font-weight: var(--regular);
}

:root {
  --tooltip-background: rgba(0, 0, 0, 0.9);
  --tooltip-border-radius: 0.25rem;
  --tooltip-font-size: 0.875rem;
  --tooltip-line-height: 1.4;
  --tooltip-font-weight: 400;
  --tooltip-letter-spacing: 0.25px;
  --tooltip-text: white;
  --tooltip-min-width: 5rem;
  --tooltip-max-width: 21rem;
  --tooltip-z-index: 100;
}

:root {
  --typeface--1: "Lato", "Helvetica Neue", sans-serif;
  --typeface--2: "Lato", "Helvetica Neue, serif";
  --t-mobile-header-font-size: 1.5rem;
  --t-mobile-header-line-height: 1.875rem;
  --t-mobile-subheader-font-size: 1.25rem;
  --t-mobile-subheader-line-height: 1.5625rem;
  --h1-font-size: 2.625rem;
  --h1-line-height: 3.25rem;
  --h2-font-size: 2.25rem;
  --h2-line-height: 2.8125rem;
  --h3-font-size: 2rem;
  --h3-line-height: 2.5rem;
  --h4-font-size: 1.75rem;
  --h4-line-height: 2.1875rem;
  --h5-font-size: 1.5rem;
  --h5-line-height: 1.875rem;
  --h6-font-size: 1.25rem;
  --h6-line-height: 1.5625rem;
  --a-p-font-size: 1.125rem;
  --a-p-line-height: 1.6875rem;
  --t-data-font-size: 1rem;
  --t-data-line-height: 1.5rem;
  --t-caption-font-size: 0.75rem;
  --t-caption-line-height: 1.125rem;
  --t-bullet-font-size: 0.875rem;
  --t-bullet-line-height: 1.125rem;
  --t-caps-letter-spacing: 0.6px;
  --t-bold-font-weight: 900;
}

:root {
  --card-shadow: 0 2px 4px 0 var(--gray--shadow);
  --footer-shadow: 0 0 4px 0 var(--gray--shadow);
  --panel-shadow: 2px 4px 0 var(--gray--shadow);
  --transition-timing: 236ms;
  --transition-timing--slow: 472ms;
  --transition-timing--fast: 118ms;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
label,
main,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

address {
  font-style: unset;
}

.container {
  width: calc(100% - 2rem);
  max-width: 84rem;
  margin: 0 auto;
}
@media (min-width: 64rem) {
  .container {
    width: calc(100% - 3rem);
  }
}
@media (min-width: 80rem) {
  .container {
    width: calc(100% - 6rem);
  }
}

.u-float-right {
  float: right;
}

.u-flex {
  display: flex;
}

.u-hidden {
  display: none;
}

.u-inline {
  display: inline;
}

.u-inline-b {
  display: inline-block;
}

.u-relative {
  position: relative;
}

.u-absolute {
  position: absolute;
}

.u-row {
  flex-direction: row;
}

.u-row-reverse {
  flex-direction: row-reverse;
}

.u-column {
  flex-direction: column;
}

.u-alignCenter {
  align-items: center;
}

.u-alignBaseline {
  align-items: baseline;
}

.u-alignEnd {
  align-items: flex-end;
}

.u-justifyCenter {
  justify-content: center;
}

.u-justifyBetween {
  justify-content: space-between;
}

.u-justifyEnd {
  justify-content: flex-end;
}

.u-fillRemaining {
  flex: 1;
}

.u-relative {
  position: relative;
}

.u-maxX {
  width: 100%;
}

.u-maxY {
  height: 100%;
}

.u-maxXY {
  height: 100%;
  width: 100%;
}

.u-hidden {
  overflow: hidden;
}

.u-scroll {
  overflow: scroll;
}

.u-wrap {
  flex-wrap: wrap;
}

.u-nowrap {
  white-space: nowrap;
}

.dashboard {
  display: grid;
  grid-template-columns: 1.5rem 1fr 1.5rem;
}
.dashboard::before {
  content: "";
  grid-column: 1/2;
}
.dashboard::after {
  content: "";
  grid-column: 3/4;
}
@media (min-width: 80rem) {
  .dashboard {
    grid-template-columns: 3rem 1fr 3rem;
  }
}
@media (min-width: 90rem) {
  .dashboard {
    grid-template-columns: 1fr 84rem 1fr;
  }
}

.dashboard__content {
  display: flex;
  grid-column: 2/3;
  height: 100%;
}

.dashboard__aside {
  width: 25%;
  padding: 2rem 3rem 2rem 0.25rem;
  overflow: scroll;
}

.dashboard__aside__chart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.dashboard__aside__progressbar + .dashboard__aside__progressbar {
  margin-top: 1.5rem;
}

.dashboard__aside__section + .dashboard__aside__section {
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.dashboard__aside__section__header {
  margin-bottom: 1.5rem;
}
.dashboard__aside__section__header .t-link {
  margin-top: 0.25rem;
}

.dashboard__aside__section__footer {
  margin-top: 1rem;
}

.dashboard__aside__link {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dashboard__aside__link .icon {
  margin-right: 0.75rem;
}
.dashboard__aside__link + .dashboard__aside__link {
  margin-top: 0.75rem;
}

.dashboard__main {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: 5rem;
}

.dashboard__header,
.dashboard__section {
  padding: 2rem 0.125rem 0 1.5rem;
}

.dashboard__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard__card {
  display: flex;
  flex-direction: column;
}

.dashboard__card__avatar {
  width: 2.625rem;
  height: 2.625rem;
  margin-right: 1rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.dashboard__card__header {
  padding: 1rem 1.5rem 0;
  margin-bottom: 1.5rem;
}
.dashboard__card__header .form-control,
.dashboard__card__header form-control {
  width: 25%;
}

.dashboard__card__body {
  flex: 1;
  padding: 0 1.5rem 2rem;
  overflow: scroll;
}

.dashboard__card__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem 0 0.625rem;
  margin-top: 0.75rem;
}
.dashboard__card__footer .t-link + .t-link {
  padding-left: 0.75rem;
  margin-left: 0.75rem;
}

.dashboard__card__paginator {
  display: flex;
  align-items: center;
}
.dashboard__card__paginator .t-data {
  margin-right: 0.25rem;
}

.dashboard__policy {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.dashboard__notification,
.dashboard__notification__content {
  display: flex;
}

.dashboard__notification {
  padding-top: 0.5rem;
}

.dashboard__notification__content {
  flex: 1;
  padding-bottom: 0.5rem;
}
.dashboard__notification__content .t-caption {
  transform: translateY(0.25rem);
}

.dashboard__notification__message {
  flex: 1;
  margin-right: 1.25rem;
}

.dashboard__card__indicator {
  width: 0.75rem;
  min-width: 0.75rem;
  max-width: 0.75rem;
  height: 0.75rem;
  min-height: 0.75rem;
  max-height: 0.75rem;
  margin-right: 1rem;
  border-radius: 50%;
  transform: translateY(0.375rem);
}

.dashboard__card__filters {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin: 1.5rem 0 1rem;
}
.dashboard__card__filters .t-data {
  margin-right: 1rem;
}

.dashboard__card__icon {
  padding: 0.3125rem;
  border-radius: 50%;
  cursor: pointer;
}

@media (min-width: 48rem) {
  .layout--detail {
    display: grid;
    grid-template-columns: 1.5rem 1fr 1.5rem;
  }
  .layout--detail::before {
    content: "";
    grid-column: 1/2;
  }
  .layout--detail::after {
    content: "";
    grid-column: 3/4;
  }
}
@media (min-width: 80rem) {
  .layout--detail {
    grid-template-columns: 3rem 1fr 3rem;
  }
}
@media (min-width: 90rem) {
  .layout--detail {
    grid-template-columns: 1fr 84rem 1fr;
  }
}

.layout--detail__content {
  display: grid;
  grid-template-columns: 20% 1fr;
  grid-column: 2/3;
}

.layout--detail__content--reverse {
  display: grid;
  grid-template-columns: 1fr 25%;
  grid-column: 2/3;
}

.layout--detail__aside,
.layout--detail__main {
  overflow: auto;
}

.layout--detail__aside {
  grid-column: 1/2;
  padding: 1.75rem 3rem 6rem 0;
}

.layout--detail__aside__header {
  padding-bottom: 1.25rem;
  margin-bottom: 1.5rem;
}

.LayoutDetails__AsideUser {
  display: flex;
  align-items: center;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
}

.LayoutDetails__AsideAvatar {
  width: 5.625rem;
  height: 5.625rem;
  margin-right: 1.5rem;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://zipari-uploads-dev.s3.amazonaws.com/files/ff00981e-e135-4bfd-98db-a9ba71503916.jpeg");
  border-radius: 50%;
}

.layout--detail__aside__section + .layout--detail__aside__section {
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}
@media (max-width: 47.9375rem) {
  .layout--detail__aside__section + .layout--detail__aside__section {
    padding-top: 1rem;
    margin-top: 0;
  }
}

.layout--detail__aside__section__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1.5rem;
}

.layout--detail__main {
  grid-column: 2/3;
  padding: 1rem 1rem 4rem;
}
@media (min-width: 48rem) {
  .layout--detail__main {
    padding: 2rem 1rem 6rem 1.5rem;
  }
}

.layout--detail__main__breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.layout--detail__main__breadcrumb .icon {
  margin-right: 0.25rem;
}
.layout--detail__main__breadcrumb .button__content {
  grid-template-columns: 1fr;
}
.layout--detail__main__breadcrumb .banner {
  flex: unset;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}
.layout--detail__main__breadcrumb .banner .t-data {
  margin: 0 0.25rem;
}

.layout--detail__main__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
@media (min-width: 48rem) {
  .layout--detail__main__header {
    margin-bottom: 2rem;
  }
}
.layout--detail__main__header .banner {
  margin-left: 1.5rem;
}
.layout--detail__main__header .form-control {
  max-width: 33.3333333333%;
}
.layout--detail__main__header .form-control + .form-control {
  margin-left: 1.5rem;
}

.layout--detail__main__status {
  margin-left: 1rem;
}

.layout--detail__main__section + .layout--detail__main__section {
  margin-top: 4rem;
}

.layout--detail__main__section__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
}

.layout--detail__footer {
  position: sticky;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0;
}
.layout--detail__footer zip-button {
  width: 16.6666666667%;
}

@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout {
    grid-template-columns: 0 1fr 1.5rem;
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__content {
    display: block;
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__content {
    grid-template-columns: 4.625rem 1fr;
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside {
    position: fixed;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100%;
    padding: 0;
    transition: transform 0.5s ease;
    z-index: var(--layer-modal);
  }
  .layout--detail--mobile-aside-flyout .layout--detail__aside--open {
    transform: translate3d(100%, 0, 0);
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside {
    display: flex;
    flex-direction: column;
    padding-right: 0;
    transition: width 0.4s ease;
    z-index: var(--layer-append);
  }
  .layout--detail--mobile-aside-flyout .layout--detail__aside--closed {
    width: 100%;
    box-shadow: 0 0 0 0.0625rem var(--gray--border);
  }
  .layout--detail--mobile-aside-flyout .layout--detail__aside--open {
    width: 21rem;
    box-shadow: var(--card-shadow);
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside__header {
    display: flex;
    align-items: center;
    min-height: var(--header-height, 3.75rem);
    padding: 0.5rem 3rem 0.5rem 0.5rem;
    margin: 0;
    color: var(--modal-panel-header-text);
    background-color: var(--modal-panel-header-background);
    box-shadow: var(--card-box-shadow);
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside__header {
    padding-bottom: 0;
    border-bottom: 0 none;
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside__header__title {
    flex: 1 1 100%;
    color: inherit;
    font-weight: var(--t-bold-font-weight);
    text-align: center;
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside__header__title {
    display: none;
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside-toggle {
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 1.0625rem;
  }
}
@media (min-width: 80rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside-toggle {
    display: none;
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside-toggle .button.button--icon .icon {
    color: var(--white);
  }
}
@media (max-width: 47.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside-content {
    padding: 1rem;
  }
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__aside-content {
    align-self: flex-end;
    width: 21rem;
    padding: 0 3rem 0 1.5rem;
    transition: opacity 0.3s ease, visibility 0.3s linear;
  }
  .layout--detail--mobile-aside-flyout .layout--detail__aside-content--closed {
    opacity: 0;
    visibility: hidden;
  }
}
@media (min-width: 48rem) {
  .layout--detail--mobile-aside-flyout .layout--detail__main__overview-btn {
    display: none;
  }
}
.layout--detail--mobile-aside-flyout .layout--detail__footer {
  z-index: var(--layer-append);
}

.layout--list {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 48rem) {
  .layout--list {
    grid-template-rows: max-content;
    grid-template-columns: 1.5rem 1fr 1.5rem;
  }
  .layout--list::before {
    content: "";
    grid-column: 1/2;
  }
  .layout--list::after {
    content: "";
    grid-column: 3/4;
  }
}
@media (min-width: 80rem) {
  .layout--list {
    grid-template-columns: 3rem 1fr 3rem;
  }
}
@media (min-width: 90rem) {
  .layout--list {
    grid-template-columns: 1fr 84rem 1fr;
  }
}
.layout--list.layout--list--injected {
  grid-template-columns: 1.5rem 1fr 1.5rem;
}
.layout--list.layout--list--injected .layout--list__header {
  margin: 0 0 1.5rem;
}

@media (min-width: 48rem) {
  .layout--list__content {
    grid-column: 2/3;
  }
}

.layout--list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0 1.5rem;
}

.inner-card {
  padding: 2rem;
  margin: 2rem 0;
}

.inner-card__header {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}
.inner-card__header .close {
  cursor: pointer;
  float: right;
}

.inner-card__secondary .t-link {
  display: block;
  margin-bottom: 1em;
  text-align: right;
}

.inner-card__wrapper {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0 2rem;
}

.inner-card__group-wrapper form-group {
  display: grid;
  grid-auto-rows: max-content;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 0 2rem;
}

.inner-card__footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
  margin-top: 1rem;
}

@media (min-width: 64rem) {
  .layout--stepper {
    display: grid;
    grid-template-columns: 1.5rem 1fr 1.5rem;
  }
  .layout--stepper::before {
    content: "";
    grid-column: 1/2;
  }
  .layout--stepper::after {
    content: "";
    grid-column: 3/4;
  }
}
@media (min-width: 80rem) {
  .layout--stepper {
    grid-template-columns: 3rem 1fr 3rem;
  }
}
@media (min-width: 90rem) {
  .layout--stepper {
    grid-template-columns: 1fr 84rem 1fr;
  }
}

@media (min-width: 64rem) {
  .layout--stepper__content {
    display: grid;
    grid-template-columns: 16.6666666667% 1fr;
    grid-column: 2/3;
  }
}

.layout--stepper__content__footer {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 2.5rem;
  margin-top: 5rem;
}

.layout--stepper__aside {
  grid-column: 1/2;
  padding: 2rem 1.5rem 5.5rem 0;
}

.layout--stepper__aside__header {
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.layout--stepper__group__wrapper {
  padding: 0.5rem 0;
}

.layout--stepper__group {
  display: flex;
  padding: 0.5rem;
  cursor: pointer;
}

.layout--stepper__group__indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.layout--stepper__subgroup {
  padding: 1rem 0 1rem 2.5rem;
  cursor: pointer;
}

.layout--stepper__main {
  padding: 1.5rem 0.125rem 5.5rem 1.5rem;
}

.layout--stepper__main__content {
  padding: 1.5rem;
}

.layout--stepper__footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0;
}

.layout--stepper--flat .layout--stepper__main {
  width: 100%;
  padding: 2.75rem 0 5.5rem 1.5rem;
  margin: 0;
}
.layout--stepper--flat .form-group {
  padding: 0;
}

:root {
  --accordion-border__bottom: var(--gray--border);
}

:root {
  --avatar-background: var(--gray--border);
}

:root {
  --badge-background: var(--white);
  --badge-border__color: var(--gray--border);
  --badge-border__width: 1px;
  --badge-border__radius: 0.25rem;
  --badge-default__color: var(--gray--border);
}

:root {
  --banner-default__radius: 0.25rem;
  --banner-pill__radius: 1.5rem;
}

:root {
  --btn-size__min-width: 6.5rem;
  --btn-size__height: 2.5rem;
  --btn-size__padding--x: 1.5rem;
  --btn-border__radius--top--left: 0.25rem;
  --btn-border__radius--top--right: 0.25rem;
  --btn-border__radius--bottom--right: 0.25rem;
  --btn-border__radius--bottom--left: 0.25rem;
  --btn-border__width: 1px;
  --btn-disabled__text: #5d6c86;
  --btn-disabled__background: var(--gray--border);
  --btn-disabled__border: var(--gray--border);
  --btn-disabled__icon: var(--text--2);
  --btn-focus__color: var(--secondary--3);
  --btn-focus__width: 4px;
  --btn-font__size: 1rem;
  --btn-font__weight: 800;
  --btn-font__line-height: 1.5rem;
  --btn-special__background--resting: var(--secondary--3);
  --btn-special__background--hover: var(--text--1);
  --btn-special__border--resting: var(--secondary--3);
  --btn-special__border--hover: var(--text--1);
  --btn-special__icon--resting: var(--text--1);
  --btn-special__icon--hover: var(--white);
  --btn-special__text--resting: var(--text--1);
  --btn-special__text--hover: var(--white);
  --btn-high__background--resting: var(--primary);
  --btn-high__background--hover: var(--text--1);
  --btn-high__border--resting: var(--primary);
  --btn-high__border--hover: var(--text--1);
  --btn-high__icon--resting: var(--white);
  --btn-high__icon--hover: var(--white);
  --btn-high__text--resting: var(--white);
  --btn-high__text--hover: var(--white);
  --btn-medium__background--resting: var(--transparent);
  --btn-medium__background--hover: var(--primary--tint);
  --btn-medium__border--resting: var(--primary);
  --btn-medium__border--hover: var(--primary);
  --btn-medium__icon--resting: var(--primary);
  --btn-medium__icon--hover: var(--primary);
  --btn-medium__text--resting: var(--primary);
  --btn-medium__text--hover: var(--primary);
  --btn-low__background--resting: var(--transparent);
  --btn-low__background--hover: var(--transparent);
  --btn-low__border--resting: var(--gray--shadow);
  --btn-low__border--hover: var(--text--2);
  --btn-low__icon--resting: var(--text--2);
  --btn-low__icon--hover: var(--text--2);
  --btn-low__text--resting: var(--text--2);
  --btn-low__text--hover: var(--text--2);
  --btn-text__icon--resting: var(--text--1);
  --btn-text__icon--hover: var(--text--1);
  --btn-text__text--resting: var(--text--1);
  --btn-text__text--hover: var(--text--1);
}

:root {
  --card-background: var(--white);
  --card-border-radius: 0.25rem;
  --card-box-shadow: var(--card-shadow);
}

:root {
  --chip-default__background: var(--gray--border);
  --chip-default__text: var(--white);
  --chip-border__radius: 0.25rem;
}

:root {
  --white: #fff;
  --black: #000;
  --transparent: rgba(255, 255, 255, 0);
  --gray--artboard: #fdfdfd;
  --gray--inactive: #f8f9fa;
  --gray--border: #eceef0;
  --gray--shadow: #c1c8d5;
  --gray--jet: #3f3e3e;
  --mask: rgba(15, 14, 14, 0.8);
  --primary: #2567ed;
  --primary--highlight: #1a469f;
  --primary--tint: #f4f8ff;
  --text--1: #0c2250;
  --text--2: #667592;
  --link: #2567ed;
  --success: #1dc291;
  --success--tint: #f4fcf9;
  --warn: #fdca40;
  --error: #e10b30;
  --error--tint: #fef3f5;
  --secondary--1: #e02271;
  --secondary--2: #fd6240;
  --secondary--3: #fdca40;
  --secondary--4: #2bcba7;
  --secondary--5: #6fd5ee;
  --secondary--6: #446ec2;
  --universal-shopping-footer-content-link-color: #2567ed;
}

::ng-deep {
  /* Customization for dropdown caret color */
}
::ng-deep .dropdown__chevron .icon {
  filter: var(--dropdown__chevron-color-filter, unset);
}

:root {
  --thin: 100;
  --extra-light: 200;
  --light: 300;
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  --extra-bold: 800;
  --heavy: 900;
}

:root {
  --fc-height: 2.5rem;
  --fc-padding-y: 0.5rem;
  --fc-padding-x: 1rem;
  --fc-font-size: 1rem;
  --fc-line-height: 1.5rem;
  --fc-background: var(--white);
  --fc-border-color: var(--gray--shadow);
  --fc-border-width: 1px;
  --fc-border-radius: 0.25rem;
  --fc-hover-background: var(--white);
  --fc-hover-border: var(--primary);
  --fc-focus-background: var(--white);
  --fc-focus-border: var(--primary);
  --fc-active-background: var(--white);
  --fc-active-border: var(--primary);
  --fc-disabled-text: var(--text--1);
  --fc-disabled-icon: var(--text--1);
  --fc-disabled-background: var(--gray--border);
  --fc-disabled-border: var(--gray--border);
  --fc-error-background: var(--transparent);
  --fc-error-border: var(--error);
  --fc-dropdown-triangle: var(--text--1);
  --fc-checkbox-radio-width: 1.25rem;
  --fc-checkbox-radio-height: 1.25rem;
  --fc-checkbox-radio-border-width: 2px;
  --fc-checkbox-radio-border-color: var(--text--1);
  --fc-checkbox-radio-disabled-background: var(--gray--shadow);
  --fc-checkbox-checked-border: var(--primary);
  --fc-checkbox-checked-background: var(--white);
  --fc-checkbox-checked-color: var(--primary);
  --fc-radio-checked-border: var(--primary);
  --fc-radio-checked-background: var(--white);
  --fc-radio-checked-color: var(--primary);
  --fc-radio-checked-height: 0.5rem;
  --fc-radio-checked-width: 0.5rem;
  --fc-menu-height: 12.5rem;
  --fc-menu-background: var(--white);
  --fc-menu-border-radius: 0.25rem;
  --fc-menu-option-hover: var(--gray--inactive);
  --fc-menu-option-highlight: var(--primary);
  --fc-textarea-height: 10rem;
  --fc-icon-color: var(--text--2);
}

::ng-deep .form-control {
  color: var(--form-control-color, var(--text--1, unset));
}

:root {
  --fg-header-border-color: var(--gray--border);
  --fg-header-border-width: 1px;
  --fg-section-border-color: var(--gray--border);
  --fg-section-border-width: 1px;
}

@font-face {
  font-family: "System Icons";
  font-weight: var(--regular);
  font-style: normal;
  src: url("https://zipari-assets-dev.s3.us-east-2.amazonaws.com/democare/6/icons/ZipariIcons.woff") format("woff");
}
@font-face {
  font-family: "Product Icons";
  font-weight: var(--regular);
  font-style: normal;
  src: url("https://s3.amazonaws.com/zipari-product-demos/icons/product-icons.woff") format("woff");
}
:root {
  --icon-color: var(--text--1);
  --icon-disabled-opacity: 0.5;
  --icon-system-small: 1.125rem;
  --icon-system-medium: 1.5rem;
  --icon-system-large: 3rem;
  --icon-product-small: 1.5rem;
  --icon-product-medium: 1.875rem;
  --icon-product-large: 3rem;
}

:root {
  --logo--light: url(https://d32ul9oyxvd2n5.cloudfront.net/assets/logo.png);
  --logo--dark: url(https://d32ul9oyxvd2n5.cloudfront.net/assets/logo.svg);
  --logo--height: 35px;
  --logo--width: 115px;
}

:root {
  --modal-background: var(--white);
  --modal-header-background: var(--gray--inactive);
  --modal-message-background: var(--gray--inactive);
  --modal-border-radius: 0.25rem;
  --modal-footer-border-color: var(--gray--border);
  --modal-footer-border-width: 2px;
  --modal-panel-header-background: var(--primary--highlight);
  --modal-panel-header-text: var(--white);
  --modal-flyout-width: 31.5rem;
  --modal-popup-width: 27rem;
  --modal-large-width: 38.625rem;
}

:root {
  --pb-height: 0.75rem;
  --pb-total-background: var(--gray--border);
  --pb-partial-background: var(--success);
}

:root {
  --switch-width: 5.625rem;
  --switch-height: 2.5rem;
  --switch-border-radius: 1.25rem;
  --switch-inactive-background: var(--gray--border);
  --switch-active-background: var(--success);
  --switch-toggle-radius: 1.25rem;
  --switch-toggle-gap: 0.25rem;
  --switch-toggle-size: 2rem;
  --switch-toggle-inactive-background: var(--text--2);
  --switch-toggle-active-background: var(--white);
  --switch-small-width: 2.8125rem;
  --switch-small-height: 1.25rem;
  --switch-small-toggle-size: 1rem;
  --switch-small-toggle-gap: 0.125rem;
}

:root {
  --table-background: var(--white);
  --table-focus-width: 1px;
  --table-focus-color: var(--gray--shadow);
  --table-actions-mobile-toggle-background: var(--secondary--1);
  --table-actions-mobile-toggle-icon: var(--white);
  --table-actions-mobile-menu-background: var(--white);
  --table-page-button-background: var(--white);
  --table-page-button-border-width: 1px;
  --table-page-button-border-color: var(--gray--border);
  --table-page-button-border-radius: 0.25rem;
  --table-page-button-active: var(--primary);
  --table-body-border-width: 1px;
  --table-body-border-color: var(--gray--border);
  --table-body-border-radius: 0.25rem;
  --table-header-background: var(--gray--border);
  --table-header-border-width: 1px;
  --table-header-border-color: var(--gray--border);
  --table-header-border-radius: 0.25rem;
  --table-header-alt-background: var(--text--1);
  --table-header-alt-border-color: var(--text--1);
  --table-header-alt-text-color: var(--white);
  --table-row-border-width: 1px;
  --table-row-border-color: var(--gray--border);
  --table-row-stripe-color: var(--white);
  --table-cell-padding-x: 0.75rem;
  --table-cell-padding-y: 0.5rem;
}

:root {
  --tabs-header-border-width: 1px;
  --tabs-header-border-color: var(--gray--shadow);
  --tabs-indicator-border-width: 3px;
  --tabs-indicator-border-color: var(--transparent);
  --tabs-inactive-text-color: var(--text--2);
  --tabs-active-border-color: var(--primary);
  --tabs-active-text-color: var(--primary);
  --tabs-active-font-weight: var(--heavy);
}

:root {
  --toggle-width-mobile: 6.25rem;
  --toggle-width-tablet: 7rem;
  --toggle-height: 2.5rem;
  --toggle-border-width: 1px;
  --toggle-border-color: var(--gray--shadow);
  --toggle-border-radius: 0.25rem;
  --toggle-font-size: 1rem;
  --toggle-line-height: 1.5rem;
  --toggle-active-background: var(--text--1);
  --toggle-active-border: var(--text--1);
  --toggle-active-text-color: var(--white);
  --toggle-active-font-weight: var(--heavy);
  --toggle-disabled-text-color: var(--gray--shadow);
  --toggle-disabled-active-background: var(--gray--border);
  --toggle-disabled-active-text-color: var(--black);
  --toggle-disabled-active-font-weight: var(--regular);
}

:root {
  --tooltip-background: rgba(0, 0, 0, 0.9);
  --tooltip-border-radius: 0.25rem;
  --tooltip-font-size: 0.875rem;
  --tooltip-line-height: 1.4;
  --tooltip-font-weight: 400;
  --tooltip-letter-spacing: 0.25px;
  --tooltip-text: white;
  --tooltip-min-width: 5rem;
  --tooltip-max-width: 21rem;
  --tooltip-z-index: 100;
}

:root {
  --typeface--1: "Lato", "Helvetica Neue", sans-serif;
  --typeface--2: "Lato", "Helvetica Neue, serif";
  --t-mobile-header-font-size: 1.5rem;
  --t-mobile-header-line-height: 1.875rem;
  --t-mobile-subheader-font-size: 1.25rem;
  --t-mobile-subheader-line-height: 1.5625rem;
  --h1-font-size: 2.625rem;
  --h1-line-height: 3.25rem;
  --h2-font-size: 2.25rem;
  --h2-line-height: 2.8125rem;
  --h3-font-size: 2rem;
  --h3-line-height: 2.5rem;
  --h4-font-size: 1.75rem;
  --h4-line-height: 2.1875rem;
  --h5-font-size: 1.5rem;
  --h5-line-height: 1.875rem;
  --h6-font-size: 1.25rem;
  --h6-line-height: 1.5625rem;
  --a-p-font-size: 1.125rem;
  --a-p-line-height: 1.6875rem;
  --t-data-font-size: 1rem;
  --t-data-line-height: 1.5rem;
  --t-caption-font-size: 0.75rem;
  --t-caption-line-height: 1.125rem;
  --t-bullet-font-size: 0.875rem;
  --t-bullet-line-height: 1.125rem;
  --t-caps-letter-spacing: 0.6px;
  --t-bold-font-weight: 900;
}

:root {
  --card-shadow: 0 2px 4px 0 var(--gray--shadow);
  --footer-shadow: 0 0 4px 0 var(--gray--shadow);
  --panel-shadow: 2px 4px 0 var(--gray--shadow);
  --transition-timing: 236ms;
  --transition-timing--slow: 472ms;
  --transition-timing--fast: 118ms;
}

.workflow-step__container {
  height: 100%;
  padding: 5rem 0;
}
@media (max-width: 47.9375rem) {
  .workflow-step__container {
    padding-top: 1rem;
    background: var(--gray--inactive);
  }
}

.workflow-step__cards {
  width: 41.25rem;
  max-width: calc(100% - 2rem);
}
@media (min-width: 48rem) {
  .workflow-step__cards {
    width: 50%;
  }
}
@media (max-width: 47.9375rem) {
  .workflow-step__cards:not(:first-child) {
    margin: 1.5rem auto 0;
  }
}

.workflow-step__card {
  width: 100%;
}
@media (min-width: 48rem) {
  .workflow-step__card {
    width: 66.6666666667%;
    margin: 1.5rem auto 0;
  }
}
@media (min-width: 80rem) {
  .workflow-step__card {
    width: 50%;
  }
}

.workflow-step__card__header {
  padding: var(--workflow-step--card-gutter, 1.5rem) var(--workflow-step--card-gutter, 1.5rem) 0;
  margin-bottom: 2rem;
}
@media (max-width: 47.9375rem) {
  .workflow-step__card__header {
    padding: var(--workflow-step--card-gutter, 1.5rem) var(--workflow-step--card-gutter, 1rem) 0;
  }
}

.workflow-step__card__body {
  padding: 0 var(--workflow-step--card-gutter, 1.5rem) 0.75rem;
}
.workflow-step__card__body h6 {
  padding-bottom: 0.5rem;
}
@media (max-width: 47.9375rem) {
  .workflow-step__card__body {
    padding: 0 var(--workflow-step--card-gutter, 1rem) 0.75rem;
  }
}
@media (max-width: 47.9375rem) {
  .workflow-step__card__body .button--high {
    width: 100% !important;
  }
}

.workflow-step__card__footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.5rem var(--workflow-step--card-gutter, 1.5rem);
}

.workflow-step__footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0;
  z-index: var(--layer-append);
}
.workflow-step__footer .button__group {
  display: none;
}
@media (min-width: 48rem) {
  .workflow-step__footer .button__group {
    display: grid;
  }
}
@media screen and (min-width: 48rem) and (-ms-high-contrast: active), (min-width: 48rem) and (-ms-high-contrast: none) {
  .workflow-step__footer .button__group {
    display: inline-flex;
  }
}

@media (min-width: 48rem) {
  .WorkflowStep__FooterButtons {
    display: none;
  }
}

:root {
  --layer-under: -100;
  --layer-base: 0;
  --layer-append: 100;
  --layer-navigation: 200;
  --layer-mask: 300;
  --layer-modal: 400;
  --layer-fixed: 500;
}

.accordion + .accordion {
  margin-top: 3rem;
}

.accordion__header {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--accordion-border__bottom);
  cursor: pointer;
}
.accordion__header .icon {
  margin-right: 0.5rem;
}
.accordion__header.is-open .icon--rotatable {
  transform: rotate(180deg);
}

.accordion--card .accordion__header,
.accordion--card .accordion__content {
  padding: 1.25rem 1.5rem;
}
.accordion--card .accordion__header {
  flex-direction: row;
  justify-content: space-between;
}
.accordion--card .accordion__header.is-open {
  padding: 1.25rem 1.5rem;
  margin: 0;
}

.avatar {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
}
.avatar svg,
.avatar img {
  border-radius: 50%;
}

.badge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 1.25rem 1.5rem;
  background-color: var(--badge-background);
  border: var(--badge-border__width) solid var(--badge-border__color);
  border-radius: var(--badge-border__radius);
  transition: box-shadow var(--transition-timing) ease;
}
@media (max-width: 79.9375rem) {
  .badge {
    margin-bottom: 1rem;
  }
}
.badge::after {
  content: "";
  position: absolute;
  top: -0.0625rem;
  bottom: -0.0625rem;
  left: -0.0625rem;
  width: var(--badge-border__radius);
  background-color: var(--primary);
  border-radius: var(--badge-border__radius) 0 0 var(--badge-border__radius);
}
.badge h4 {
  margin-bottom: 0.25rem;
}

.badge--1::after {
  background-color: var(--secondary--1);
}
.badge--1 .badge__action .icon {
  color: var(--secondary--1);
}

.badge--2::after {
  background-color: var(--secondary--2);
}
.badge--2 .badge__action .icon {
  color: var(--secondary--2);
}

.badge--3::after,
.badge--warn::after,
.badge--in-progress::after {
  background-color: var(--secondary--3);
}
.badge--3 .badge__action .icon,
.badge--warn .badge__action .icon,
.badge--in-progress .badge__action .icon {
  color: var(--secondary--3);
}

.badge--4::after {
  background-color: var(--secondary--4);
}
.badge--4 .badge__action .icon {
  color: var(--secondary--4);
}

.badge--5::after {
  background-color: var(--secondary--5);
}
.badge--5 .badge__action .icon {
  color: var(--secondary--5);
}

.badge--6::after {
  background-color: var(--secondary--6);
}
.badge--6 .badge__action .icon {
  color: var(--secondary--6);
}

.badge--error::after {
  background-color: var(--error);
}
.badge--error .badge__action .icon {
  color: var(--error);
}

.badge--success::after {
  background-color: var(--success);
}
.badge--success .badge__action .icon {
  color: var(--success);
}

.badge--expired::after {
  background-color: var(--badge-default__color);
}
.badge--expired .badge__action .icon {
  color: var(--badge-default__color);
}

.badge--small {
  padding: 0.5rem 1rem 0.625rem;
}

.badge__actions {
  width: 66.6666666667%;
}

.badge__action {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.badge__action .icon {
  margin-right: 1rem;
}
.badge__action + .badge__action {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid var(--badge-border__color);
}

.badge__group {
  display: flex;
}
.badge__group .badge + .badge {
  margin-left: 1.5rem;
}
.badge__group badge + badge {
  margin-left: 1.5rem;
}

.badge__group--stacked {
  flex-direction: column;
}
.badge__group--stacked .badge + .badge {
  margin: 1.5rem 0 0;
}
.badge__group--stacked badge + badge {
  margin: 1.5rem 0 0;
}

.banner {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--primary--tint);
  border-radius: var(--banner-border__radius);
}

.banner__icon {
  margin-right: 0.5rem;
}

.banner__content {
  flex: 1;
}

.banner--pill {
  width: -moz-fit-content;
  width: fit-content;
  padding: 0.25rem 0.75rem;
  border-radius: var(--banner-pill__radius);
}

.banner--error {
  background-color: var(--error--tint);
}
.banner--error .icon {
  color: var(--error);
}

.banner--success {
  background-color: var(--success--tint);
}
.banner--success .icon {
  color: var(--success);
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  transform: translateX(-0.375rem);
}
.breadcrumb .icon {
  margin-right: 0.25rem;
}

.button__group {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-gap: 1rem;
  align-items: center;
}
.button__group button {
  width: 100%;
}

:host {
  display: block;
  width: 100%;
}

.card {
  width: 100%;
  background-color: var(--card-background);
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-shadow);
}
.card header {
  margin-bottom: 1.5rem;
}

card .card {
  padding: 1rem 1.5rem 1.25rem;
}

.card--header {
  display: flex;
  padding-bottom: 1.5rem;
}
.card--header > div:last-of-type {
  margin-left: auto;
}

.card--header__title {
  width: 100%;
}

.card--stretch {
  height: -moz-available;
  height: stretch;
}

.card--footer {
  bottom: 1rem;
  margin-top: 1rem;
}
.card--footer.static {
  position: static;
}

.chip {
  width: -moz-max-content;
  width: max-content;
  padding: 0.125rem 1rem;
  background-color: var(--chip-default__background);
  border-radius: var(--chip-border__radius);
}

.chip--1 {
  background-color: var(--secondary--1);
}
.chip--1 .t-caption {
  color: var(--chip-default__text);
}

.chip--2 {
  background-color: var(--secondary--2);
}
.chip--2 .t-caption {
  color: var(--chip-default__text);
}

.chip--3 {
  background-color: var(--secondary--3);
}
.chip--3 .t-caption {
  color: var(--text--1);
}

.chip--4 {
  background-color: var(--secondary--4);
}
.chip--4 .t-caption {
  color: var(--chip-default__text);
}

.chip--5 {
  background-color: var(--secondary--5);
}
.chip--5 .t-caption {
  color: var(--chip-default__text);
}

.chip--6 {
  background-color: var(--secondary--6);
}
.chip--6 .t-caption {
  color: var(--chip-default__text);
}

.chip--success {
  background-color: var(--success);
}
.chip--success .t-caption {
  color: var(--chip-default__text);
}

.chip--warn {
  background-color: var(--warn);
}
.chip--warn .t-caption {
  color: var(--text--1);
}

.chip--info {
  background-color: var(--primary--highlight);
}
.chip--info .t-caption {
  color: var(--white);
}

.chip--error {
  background-color: var(--error);
}
.chip--error .t-caption {
  color: var(--chip-default__text);
}

.chip--black {
  background-color: var(--gray--jet);
}
.chip--black .t-caption {
  color: var(--chip-default__text);
}

/* element resets */
input,
select,
textarea {
  width: 100%;
  margin: 0;
  outline: 0;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  font-size: var(--fc-font-size);
  line-height: var(--fc-line-height);
  vertical-align: middle;
  white-space: normal;
  box-sizing: border-box;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

textarea {
  resize: none;
}

.form-control {
  height: var(--fc-height);
  padding: var(--fc-padding-y) var(--fc-padding-x);
  background-color: var(--fc-background);
  border: var(--fc-border-width) solid var(--fc-border-color);
  border-radius: var(--fc-border-radius);
  transition: border-color var(--transition-timing) ease, border-width var(--transition-timing) ease, padding var(--transition-timing) ease;
}
.form-control:hover {
  background-color: var(--fc-hover-background);
  border-color: var(--fc-hover-border);
}
.form-control:focus {
  background-color: var(--fc-focus-background);
  border-color: var(--fc-focus-border);
}
.form-control.is-active {
  background-color: var(--fc-active-background);
  border-color: var(--fc-active-border);
}
.form-control[readonly], .form-control[disabled] {
  color: var(--fc-disabled-text);
  background-color: var(--fc-disabled-background);
  border-color: var(--fc-disabled-border);
  pointer-events: none;
}
.form-control[readonly].form-control--checkbox, .form-control[readonly].form-control--radio, .form-control[disabled].form-control--checkbox, .form-control[disabled].form-control--radio {
  border-color: var(--transparent);
}
.form-control[readonly].form-control--checkbox:checked::after, .form-control[readonly].form-control--radio:checked::after, .form-control[disabled].form-control--checkbox:checked::after, .form-control[disabled].form-control--radio:checked::after {
  background-color: var(--fc-checkbox-radio-disabled-background);
}
.form-control.ng-invalid.ng-touched {
  background-color: var(--fc-error-background);
  border-color: var(--fc-error-border);
}

/* default wrapper */
.form-control__wrapper {
  position: relative;
  padding-bottom: 1.5rem;
  margin-bottom: 0.75rem;
}
@media (min-width: 48rem) {
  .form-control__wrapper {
    margin-bottom: 1rem;
  }
}
.form-control__wrapper .form-control__description {
  margin-bottom: 2rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-control__wrapper control-toggle {
    display: flex;
  }
}

/* inline wrapper - label & input displayed horizontally */
.form-control__wrapper--inline {
  display: flex;
  align-items: center;
}
.form-control__wrapper--inline .form-control__label {
  width: 25%;
  margin: 0 1.5rem 0 0;
}
.form-control__wrapper--inline .form-control {
  flex: 1;
}
.form-control__wrapper--inline .form-control__error,
.form-control__wrapper--inline .form-control__hint {
  bottom: -0.375rem;
  margin-top: 1.375rem;
}
.form-control__wrapper--inline zip-button {
  margin-left: 1.5rem;
}
.form-control__wrapper--inline control-checkbox {
  flex: 1;
}

/* flat wrapper - no vertical spacing */
.form-control__wrapper--flat {
  padding-bottom: 0;
  margin-bottom: 0;
}

.form-control__wrapper--grouping {
  display: flex;
  flex-direction: column-reverse;
}
.form-control__wrapper--grouping .form-control__label {
  margin: 0.25rem 0 0 1rem;
}

/* password rules callout */
.form-control__complex-password {
  position: absolute;
  top: 7rem;
  left: 0;
  min-height: 5rem;
  padding: 1rem;
  background-color: var(--white);
  z-index: var(--layer-append);
  box-shadow: var(--card-shadow);
}
.form-control__complex-password::after {
  content: "";
  position: absolute;
  top: 1px;
  left: 4rem;
  width: 0;
  height: 0;
  border: 0.5rem solid var(--white);
  border-color: transparent transparent var(--white) var(--white);
  transform: rotate(-224deg);
  box-shadow: -1px 1px 0 0 var(--gray--shadow);
  transform-origin: 0 0;
  box-sizing: border-box;
}

.form-control__complex-password__label + .form-control__complex-password__rules {
  margin-bottom: 0.25rem;
}
.form-control__complex-password__label + .form-control__complex-password__description {
  margin-bottom: 0.5rem;
}

.form-control__complex-password__rule {
  display: flex;
}
.form-control__complex-password__rule p {
  margin-left: 0.5rem;
}
.form-control__complex-password__rule + .form-control__complex-password__rule {
  margin-top: 0.25rem;
}

/* default label */
.form-control__label {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
.form-control__label .icon {
  margin-left: 0.5rem;
}

.form-control__label--hidden {
  opacity: 0;
}

.form-control__label__link {
  display: flex;
  flex: 1;
}

/* dropdown - styled native <select>*/
.form-control--dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  border: 0;
}
.form-control--dropdown select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-right: 2rem;
  padding-left: 1rem;
  background-color: var(--transparent);
  border: var(--fc-border-width) solid var(--fc-border-color);
  border-radius: var(--fc-border-radius);
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form-control--dropdown select.ng-invalid.ng-touched {
  background-color: var(--fc-error-background);
  border-color: var(--fc-error-border);
}
.form-control--dropdown select:disabled {
  background-color: var(--fc-disabled-background);
  border-color: var(--fc-disabled-border);
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .form-control--dropdown select::-ms-expand {
    display: none;
  }
}

/* default checkbox & radio button*/
.form-control--checkbox,
.form-control--radio {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--fc-checkbox-radio-width);
  min-width: var(--fc-checkbox-radio-width);
  max-width: var(--fc-checkbox-radio-width);
  height: var(--fc-checkbox-radio-height);
  min-height: var(--fc-checkbox-radio-height);
  max-height: var(--fc-checkbox-radio-height);
  padding: 0;
  border: var(--fc-checkbox-radio-border-width) solid var(--fc-checkbox-radio-border-color);
}

.form-control--checkbox:checked {
  color: var(--fc-checkbox-checked-color);
  background-color: var(--fc-checkbox-checked-background);
  border: var(--fc-checkbox-radio-border-width) solid var(--fc-checkbox-checked-border);
}
.form-control--checkbox:checked::after {
  content: "check";
  font-family: "System Icons";
}
.form-control--checkbox.form-control--checkbox-partial::after {
  content: "\e90f";
  font-family: "System Icons";
}

.form-control--radio {
  border-radius: 50%;
}
.form-control--radio:checked {
  background-color: var(--fc-radio-checked-background);
  border-color: var(--fc-radio-checked-border);
}
.form-control--radio:checked::after {
  content: "";
  min-width: var(--fc-radio-checked-width);
  height: var(--fc-radio-checked-height);
  background-color: var(--fc-radio-checked-color);
  border-radius: 50%;
}

/* default checkbox & radio wrappers - vertical checkbox/radio group + horizontal checkbox/radio options */
.form-control--checkbox__wrapper,
.form-control--radio__wrapper {
  display: flex;
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}
.form-control--checkbox__wrapper .form-control--checkbox,
.form-control--checkbox__wrapper .form-control--radio,
.form-control--radio__wrapper .form-control--checkbox,
.form-control--radio__wrapper .form-control--radio {
  margin-right: 0.5rem;
}
.form-control--checkbox__wrapper + .form-control--checkbox__wrapper,
.form-control--checkbox__wrapper + .form-control--radio__wrapper,
.form-control--radio__wrapper + .form-control--checkbox__wrapper,
.form-control--radio__wrapper + .form-control--radio__wrapper {
  margin-top: 0.75rem;
}

/* inline checkbox & radio wrappers - horizontal checkbox/radio group + horizontal checkbox/radio options */
.form-control--checkbox__wrapper--inline,
.form-control--radio__wrapper--inline {
  display: inline-flex;
  width: 25%;
  padding: 0;
  margin: 0;
}

/* justified checkbox & radio wrappers - vertical checkbox/radio group + opposite-ends checkbox/radio options */
.form-control--radio__checkbox--justify,
.form-control--radio__wrapper--justify {
  justify-content: space-between;
  width: 100%;
}
.form-control--radio__checkbox--justify .form-control--checkbox,
.form-control--radio__checkbox--justify .form-control--radio,
.form-control--radio__wrapper--justify .form-control--checkbox,
.form-control--radio__wrapper--justify .form-control--radio {
  margin: 0 0 0 1rem;
}

/* dropdown menu - standalone scrollable menu. it is NOT used with .form-control--dropdown */
.form-control__menu {
  position: absolute;
  top: 100%;
  width: 100%;
  max-height: var(--fc-menu-height);
  background-color: var(--fc-menu-background);
  z-index: var(--layer-modal);
  overflow-y: scroll;
  border-bottom-right-radius: var(--fc-menu-border-radius);
  border-bottom-left-radius: var(--fc-menu-border-radius);
  box-shadow: var(--card-shadow);
}

.form-control__menu__option {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color var(--transition-timing) ease;
  overflow: hidden;
}
.form-control__menu__option:hover, .form-control__menu__option.is-active {
  background-color: var(--fc-menu-option-hover);
}
.form-control__menu__option.is-active .t-data {
  font-weight: var(--heavy);
}

/* typeahead highlight */
.form-control__menu__option__highlight {
  display: contents;
  color: var(--fc-menu-option-highlight);
}

/* errors & help text */
.form-control__error,
.form-control__hint {
  position: absolute;
  left: 1rem;
  margin-top: 0.25rem;
}
.form-control__error .icon,
.form-control__hint .icon {
  margin-right: 0.25rem;
}
.form-control__error p,
.form-control__hint p {
  height: 0;
  white-space: pre-wrap;
}

.form-control__error {
  opacity: 0;
}
.form-control__error.is-invalid {
  opacity: 1;
}

/* default icon - left position */
.form-control__icon {
  position: absolute;
  top: var(--fc-icon-top, var(--fc-padding-y));
  right: var(--fc-padding-x);
}
.form-control__icon .icon {
  color: var(--fc-icon-color);
}
.form-control__icon + .form-control {
  padding-right: 3rem;
}
@media (min-width: 48rem) {
  .form-control__icon {
    right: auto;
    left: var(--fc-padding-x);
  }
  .form-control__icon + .form-control {
    padding: 0 0 0 3rem;
  }
}

/* icon - right position */
@media (min-width: 48rem) {
  .form-control__icon--right {
    right: var(--fc-padding-x);
    left: auto;
  }
  .form-control__icon--right + .form-control {
    padding: 0 3rem 0 0;
  }
}

.form-control--textarea {
  height: auto;
  max-height: var(--fc-textarea-height);
  overflow: auto;
}

.form-control-textarea__remaining-characters {
  padding: 0.25rem 0 0.25rem 0.75rem;
  font-size: 0.75rem;
}

.form-group + .form-group {
  margin-top: 3rem;
}

.form-group__header {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1.25rem;
  border-bottom: var(--fg-header-border-width) solid var(--fg-header-border-color);
}
.form-group__header .icon {
  margin-right: 0.75rem;
}

.form-group__header__action {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.form-group__header__action .icon {
  margin-right: 0.25rem;
}

.form-group__header--tight {
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.form-group__section {
  display: flex;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: var(--fg-section-border-width) solid var(--fg-section-border-color);
}

.icon--pointer,
.icon--link {
  cursor: pointer;
}

.icon--move {
  cursor: move;
}

.icon--rotate90 {
  transform: rotate(90deg);
}

.icon--rotate180 {
  transform: rotate(180deg);
}

.icon--flip {
  transform: scaleX(-1);
}

.icon {
  display: inline-block;
  transition: opacity var(--transition-timing) ease, transform var(--transition-timing) ease, color var(--transition-timing) ease;
  font-family: "System Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
}
.icon.is-disabled {
  pointer-events: none;
  opacity: var(--icon-disabled-opacity);
}

.icon--small {
  font-size: var(--icon-system-small);
}

.icon--medium {
  font-size: var(--icon-system-medium);
}

.icon--large {
  font-size: var(--icon-system-large);
}

.icon--product {
  font-family: "System Icons";
}
.icon--product.icon--small {
  font-size: var(--icon-product-small);
}
.icon--product.icon--medium {
  font-size: var(--icon-product-medium);
}
.icon--product.icon--large {
  font-size: var(--icon-product-large);
}

.icon--primary,
.icon--link {
  color: var(--primary);
}

.icon--alt {
  color: var(--text--2);
}

.icon--success {
  color: var(--success);
}

.icon--error {
  color: var(--error);
}

.layout--stepper--form--card--container .form--controls {
  width: 100%;
}
@media (min-width: 48rem) {
  .layout--stepper--form--card--container .form--controls {
    width: 83%;
  }
}

/** special way to handle responsiveness for layouts that allows you to explicitly call out specific templates */
@media (min-width: 0) and (max-width: 47.9375rem) {
  .layout--mobile {
    display: block;
  }
  .layout--tablet,
  .layout--desktop {
    display: none;
  }
}
@media (min-width: 48rem) and (max-width: 63.9375rem) {
  .layout--tablet {
    display: block;
  }
  .layout--mobile,
  .layout--desktop {
    display: none;
  }
}
@media (min-width: 64rem) {
  .layout--desktop {
    display: block;
  }
  .layout--tablet,
  .layout--mobile {
    display: none;
  }
}
.loader__mask {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: var(--layer-modal);
}
.loader__mask.is-aligned-top {
  align-items: flex-start;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  width: var(--loader-width, 10rem);
  height: var(--loader-height, 10rem);
  background-image: var(--loader-bg-img, unset);
  background-position: var(--loader-bg-position, unset);
  background-repeat: var(--loader-bg-repeat, unset);
  border: var(--loader-border, 1rem solid var(--primary));
  border-top-color: var(--transparent);
  border-radius: var(--loader-border-radius, 50%);
  transform: var(--loader-transform, translateZ(0));
  animation: var(--loader-animation, spin 1.4s infinite linear);
}

.table__body__loader {
  padding: 1rem;
}
.table__body__loader .loader.inner-loader {
  width: var(--inner-loader-width, 4rem);
  height: var(--inner-loader-height, 4rem);
  margin: 0 auto;
  border: var(--inner-loader-border, 0.5rem solid var(--primary));
  border-top-color: var(--transparent);
  border-radius: var(--inner-loader-border-radius, 50%);
  transform: var(--inner-loader-transform, translateZ(0));
  animation: var(--inner-loader-animation, spin 0.9s infinite linear);
}

/* the !importants are used to override the default behavior of the 3rd party module */
.gm-style-iw,
.gm-style-iw-c {
  padding: 0 !important;
  padding-top: 1.625rem !important;
  border-radius: 0.25rem;
}
.gm-style-iw::after,
.gm-style-iw-c::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 1.625rem;
  background-color: var(--gray--inactive);
  z-index: var(--layer-base);
}
.gm-style-iw button,
.gm-style-iw-c button {
  top: -0.1875rem !important;
  right: 0 !important;
  z-index: var(--layer-append);
}

.gm-style-iw,
.gm-style-iw-c,
.gm-style-iw-d {
  max-width: 14rem !important;
  max-height: unset !important;
  overflow: unset !important;
}

.popup-container {
  display: flex;
  align-items: center;
  margin: 1rem;
}
.popup-container img {
  width: 2.5rem;
  border-radius: 50%;
}

.popup-content {
  flex: 1;
  padding-left: 1rem;
}

.modal__mask,
.modal__mask-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--mask);
  opacity: var(--mask--opacity);
}

.modal__mask--browser--support {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--mask--browser--support, #ffffff);
  opacity: 1;
  z-index: var(--layer-mask);
}

.modal__mask {
  z-index: var(--layer-mask);
}

.modal__mask--modal {
  z-index: var(--layer-modal);
}

.custom--modal.modal {
  position: fixed;
  width: 100%;
  background-color: var(--modal-background);
  border-radius: var(--modal-border-radius);
  z-index: var(--layer-modal);
}
.custom--modal.modal--small {
  top: 60%;
  left: 50%;
  width: calc(100vw - 2rem);
  max-width: 27rem;
  transform: translate3d(-50%, -100%, 0);
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}
.custom--modal.modal--small .modal__header,
.custom--modal.modal--small .modal__body,
.custom--modal.modal--small .modal__footer {
  padding: 0.5rem 1.5rem;
}
.custom--modal.modal--small .modal__header {
  background-color: var(--modal-header-background);
  border-top-right-radius: var(--modal-border-radius);
  border-top-left-radius: var(--modal-border-radius);
}
.custom--modal.modal--small .modal__footer {
  border-top: var(--modal-footer-border-width) solid var(--modal-footer-border-color);
  border-bottom-right-radius: var(--modal-border-radius);
  border-bottom-left-radius: var(--modal-border-radius);
}
.custom--modal.modal--panel {
  display: grid;
  grid-template-rows: 3.5rem calc(100vh - 9rem) auto;
  grid-template-columns: 1fr;
  top: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 48rem) {
  .custom--modal.modal--panel {
    max-width: 31.5rem;
  }
}
.custom--modal.modal--panel .modal__header,
.custom--modal.modal--panel .modal__body,
.custom--modal.modal--panel .modal__footer {
  padding: 1rem;
}
@media (min-width: 48rem) {
  .custom--modal.modal--panel .modal__header,
  .custom--modal.modal--panel .modal__body,
  .custom--modal.modal--panel .modal__footer {
    padding-right: 3rem;
  }
}
.custom--modal.modal--panel .modal__body {
  overflow-y: auto;
}
.custom--modal.modal--panel .modal__header {
  background-color: var(--modal-panel-header-background);
}
.custom--modal.modal--panel .modal__header .t-data,
.custom--modal.modal--panel .modal__header .icon {
  color: var(--modal-panel-header-text);
}
.custom--modal.modal--panel .modal__header .icon--medium {
  margin-right: 0.25rem;
}
.custom--modal.modal--panel .modal__header .t-underline {
  text-decoration: underline;
}
.custom--modal.modal--panel .modal__body--full {
  padding-right: 0;
}
.custom--modal.modal--panel .modal__message {
  margin-bottom: 1rem;
  background-color: var(--modal-message-background);
}
@media (min-width: 48rem) {
  .custom--modal.modal--panel .modal__message {
    padding: 1rem;
    margin: -1rem -3rem 1rem -1rem;
    background-color: var(--gray--inactive);
  }
}
.custom--modal.modal--panel .modal__message h4 {
  padding-bottom: 1rem;
}
.custom--modal.modal--panel .modal__footer {
  display: flex;
  border-top: 2px solid var(--gray--border);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.custom--modal.modal--panel .modal__footer .button {
  margin-right: 0.5rem;
}
.custom--modal.modal__header, .custom--modal.modal__footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 48rem) {
  .custom--modal.modal__header, .custom--modal.modal__footer {
    justify-content: space-between;
  }
}
.custom--modal.modal__header {
  grid-row: 1;
  padding: 1rem 1.5rem;
}
.custom--modal.modal__back {
  position: absolute;
  left: 1rem;
}
.custom--modal.modal__body {
  grid-row: 2;
  height: 100%;
  padding: 1.5rem;
  overflow: scroll;
}
.custom--modal.modal__body .t-data + .t-data {
  margin-top: 1.5rem;
}
.custom--modal.modal__body--full {
  padding: 0;
}
.custom--modal.modal__footer {
  grid-row: 3;
  padding: 0.5rem 1.5rem;
  z-index: var(--layer-append);
}
.custom--modal.modal__footer .t-data {
  cursor: pointer;
}
.custom--modal.modal__footer--justifyEnd {
  display: flex;
  justify-content: flex-end;
}

.model-attribute {
  display: flex;
  flex-direction: column;
  word-break: break-all;
}
.model-attribute .icon {
  margin-right: 1.25rem;
}
@media (min-width: 80rem) {
  .model-attribute + .model-attribute {
    margin-top: 1.5rem;
  }
}

.model-attribute__label {
  margin-bottom: 0.125rem;
}

.model-attribute__label,
.model-attribute__value {
  white-space: pre-wrap;
  word-break: break-word;
}

ul {
  list-style: none;
}

.model-attribute__wrapper {
  width: 100%;
}
.model-attribute__wrapper.u-row .model-attribute__label {
  width: 25%;
  margin-right: 1rem;
  word-break: break-word;
}

model-attribute + model-attribute {
  margin-top: 1rem;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  model-attribute {
    height: 4.625rem;
  }
}

.progressbar__label {
  margin-bottom: 0.25rem;
}

.progressbar__total,
.progressbar__partial {
  height: var(--pb-height);
  border-radius: var(--pb-height)/2;
}

.progressbar__total {
  position: relative;
  background-color: var(--pb-total-background);
}

.progressbar__partial {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--pb-partial-background);
}

.progressbar__percentage {
  margin-top: 0.75rem;
}

.progressbar__legend {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
}

.progressbar__error {
  background-color: var(--error);
}

.switch {
  position: relative;
  width: var(--switch-width);
  height: var(--switch-height);
  background-color: var(--switch-inactive-background);
  border-radius: var(--switch-border-radius);
  cursor: pointer;
  transition: background-color var(--transition-timing) ease;
}
.switch::after {
  content: "";
  position: absolute;
  top: var(--switch-toggle-gap);
  left: var(--switch-toggle-gap);
  width: var(--switch-toggle-size);
  height: var(--switch-toggle-size);
  background-color: var(--switch-toggle-inactive-background);
  border-radius: 50%;
  transition: left var(--transition-timing) ease, color var(--transition-timing) ease;
}
.switch.is-active {
  background-color: var(--switch-active-background);
}
.switch.is-active::after {
  left: calc(100% - (var(--switch-toggle-size) + var(--switch-toggle-gap)));
  background-color: var(--switch-toggle-active-background);
}

.switch--small {
  width: var(--switch-small-width);
  height: var(--switch-small-height);
}
.switch--small::after {
  top: var(--switch-small-toggle-gap);
  left: var(--switch-small-toggle-gap);
  width: var(--switch-small-toggle-size);
  height: var(--switch-small-toggle-size);
}
.switch--small.is-active::after {
  left: calc(100% - (var(--switch-toggle-size) / 2 + var(--switch-toggle-gap) / 2));
}

.table {
  background-color: var(--table-background);
  outline: none;
}
.table .table--checkbox {
  position: absolute;
  top: 30%;
  left: 1rem;
  width: 1rem;
  min-width: 1.125rem;
  max-width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  max-height: 1.125rem;
}
.table:focus {
  border: var(--table-focus-width) solid var(--table-focus-color);
}

.table,
.table__header__wrapper,
.table__row__wrapper {
  display: block;
}

.table__header,
.table__row,
.table__footer,
.table__cell,
.table__pagination,
.table__row__expand {
  display: flex;
}

.table__cell,
.table__pagination,
.table__row__expand {
  align-items: center;
}

.table__cell {
  flex: 1;
}

.table__top {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  margin-top: 0.75rem;
}
@media (min-width: 48rem) {
  .table__top {
    grid-template-columns: 1fr max-content;
    grid-row-gap: 1.5rem;
    margin: 1.5rem 0;
  }
}
@media (min-width: 80rem) {
  .table__top {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr repeat(2, max-content);
    margin: 2rem 0 2.5rem;
  }
}

.table__top__title {
  grid-row: 1/2;
  align-self: center;
  padding: 0 1rem;
}
@media (min-width: 48rem) {
  .table__top__title {
    grid-column: 1/2;
    padding: 0;
  }
}

.table__top__filters {
  grid-row: 2/3;
  grid-column: 1/3;
}
@media (min-width: 80rem) {
  .table__top__filters {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.table__top__filters--new-row {
  grid-row: 2/3;
  grid-column: 1/2;
}

.table__top__actions {
  display: none;
}
@media (min-width: 48rem) {
  .table__top__actions {
    display: block;
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
@media (min-width: 80rem) {
  .table__top__actions {
    grid-column: 3/4;
    padding-top: 2rem;
    margin-left: 1.5rem;
  }
}

.table__actions--mobile,
.table__actions--mobile__menu {
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
@media (min-width: 48rem) {
  .table__actions--mobile,
  .table__actions--mobile__menu {
    display: none;
  }
}

.table__actions--mobile__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--table-actions-mobile-toggle-background);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: var(--card-shadow);
}
.table__actions--mobile__toggle .icon {
  color: var(--table-actions-mobile-toggle-icon);
}

.table__actions--mobile__menu {
  padding: 0.25rem 0;
  background-color: var(--white);
  border-radius: 0.25rem;
  z-index: var(--layer-modal);
}
.table__actions--mobile__menu .form-control__menu__option {
  padding: 0.5rem 1rem;
}

.table__cell--header {
  cursor: pointer;
}
.table__cell--header .button__group {
  margin-left: auto;
}
.table__cell--header .icon {
  margin-left: 0.5rem;
}

.table__footer {
  flex-direction: column;
  padding: 0 1rem;
  margin-top: 0.75rem;
}
@media (min-width: 48rem) {
  .table__footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}

.table__footer__count {
  margin-bottom: 0.5rem;
}
@media (min-width: 48rem) {
  .table__footer__count {
    margin: 0;
  }
}

.table__cell {
  padding: var(--table-cell-padding-y) var(--table-cell-padding-x);
  word-break: break-word;
}

.table__cell__actions {
  display: flex;
}
.table__cell__actions icon {
  padding: 0 0.5rem;
}
.table__cell__actions .icon + .icon {
  border-left: 1px solid var(--gray--border);
}

.table__pagination__button {
  padding: 0 0.5rem;
  background-color: var(--table-page-button-background);
  border: var(--table-page-button-border-width) solid var(--table-page-button-border-color);
  border-right: 0;
  cursor: pointer;
  outline: 0;
}
.table__pagination__button .t-bold {
  color: var(--table-page-button-active);
}
.table__pagination__button:first-of-type {
  border-top-left-radius: var(--table-page-button-border-radius);
  border-bottom-left-radius: var(--table-page-button-border-radius);
}
.table__pagination__button:last-of-type {
  border-right: var(--table-page-button-border-width) solid var(--table-page-button-border-color);
  border-top-right-radius: var(--table-page-button-border-radius);
  border-bottom-right-radius: var(--table-page-button-border-radius);
}
.table__pagination__button.is-disabled, .table__pagination__button:disabled {
  cursor: not-allowed;
}

.table__body {
  height: 100%;
  min-height: 3rem;
}
@media (min-width: 48rem) {
  .table__body {
    border: var(--table-body-border-width) solid var(--table-body-border-color);
    border-top: 0;
    border-bottom-right-radius: var(--table-body-border-radius);
    border-bottom-left-radius: var(--table-body-border-radius);
  }
}

.table__row__wrapper--nested .table__row {
  position: relative;
  width: 100%;
  padding-left: 2rem;
}

.table__row__wrapper--hover:hover {
  background-color: var(--gray--border);
}

.table__row__wrapper--group .table__row {
  padding-left: 1.5rem;
}

.table__header {
  position: relative;
}
.table__header .header--checkbox {
  top: 1rem;
}

.table__header__wrapper {
  background-color: var(--table-header-background);
}
@media (min-width: 48rem) {
  .table__header__wrapper {
    border: var(--table-header-border-width) solid var(--table-header-border-color);
    border-top-left-radius: var(--table-header-border-radius);
    border-top-right-radius: var(--table-header-border-radius);
  }
}

.table__header__wrapper--dark {
  background-color: var(--table-header-alt-background);
  border-color: var(--table-header-alt-border-color);
}
.table__header__wrapper--dark .icon,
.table__header__wrapper--dark .t-data {
  color: var(--table-header-alt-text-color);
}

.table__row,
.table__header {
  width: 100%;
  padding: 0 1rem;
}
@media (min-width: 48rem) {
  .table__row,
  .table__header {
    padding: 0 1.5rem;
  }
  .table__row.multiple-select,
  .table__header.multiple-select {
    padding-left: 2.5rem;
    margin: 0;
  }
}

.table__row__wrapper.is-expanded {
  border-left: 2px solid var(--text--1);
  box-shadow: 0 2px 8px 0 var(--gray--shadow);
}
.table__row__wrapper.is-expanded.table__row__wrapper--nested {
  border-left: 1px solid var(--gray--border);
  box-shadow: none;
}
.table__row__wrapper.is-expanded.table__row__wrapper--nested .t-data {
  font-weight: var(--heavy);
}
.table__row__wrapper:nth-of-type(odd) {
  background-color: var(--table-row-stripe-color);
}

.table__row {
  border-bottom: var(--table-row-border-width) solid var(--table-row-border-color);
}
.table__row.is-selected {
  background-color: var(--gray--inactive);
  box-shadow: inset 4px 0 0 var(--primary);
}
.table__row.is-selected .t-data {
  font-weight: var(--heavy);
}
.table__row:hover .table__row__indicator {
  opacity: 1;
}
.table__row.table__row-nodata {
  border-bottom: 0;
}
@media (min-width: 48rem) {
  .table__row {
    width: calc(100% - 3rem);
    padding: 0;
    margin: 0 1.5rem;
  }
}

.table__row--u-maxX {
  width: 100%;
}

.table__row__indicator {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  opacity: 0;
}
.table__row__indicator.is-active {
  opacity: 1;
}
.table__row__indicator.is-active .icon {
  color: var(--primary);
}

.table__row__details {
  padding-left: 3rem;
  background-color: var(--white);
  border-right: 1px solid var(--gray--border);
  border-left: 2px solid var(--text--1);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 var(--gray--shadow);
}
@media (min-width: 48rem) {
  .table__row__details {
    padding-left: 3.5rem;
  }
}

.table__row__detail {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray--border);
}
.table__row__detail:last-of-type {
  border-bottom: 0;
}
@media (min-width: 48rem) {
  .table__row__detail {
    padding: 0.75rem 0;
  }
}

.table__row__detail__label {
  margin-bottom: 0.125rem;
}
@media (min-width: 48rem) {
  .table__row__detail__label .t-caption {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.table--message .table__body {
  border-top: 1px solid var(--gray--border);
}
.table--message .table__cell {
  flex-direction: column;
  align-items: baseline;
  flex: initial;
}

.table__row .table__cell .status {
  width: auto;
}

.table__row .table__cell .status__text {
  white-space: pre-wrap;
}

.Tabs {
  display: flex;
  border-bottom: var(--tabs-header-border-width) solid var(--tabs-header-border-color);
}

.Tab {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Tab__TitleText {
  width: -moz-fit-content;
  width: fit-content;
  padding-bottom: 1rem;
  color: var(--tabs-inactive-text-color);
  border-bottom: var(--tabs-indicator-border-width) solid var(--tabs-indicator-border-color);
  cursor: pointer;
  transform: translateY(0.0625rem);
  transition: var(--transition-timing) ease, font-weight var(--transition-timing) ease;
}
.Tab.Tab--Active .Tab__TitleText {
  color: var(--tabs-active-font-weight);
  font-weight: var(--tabs-active-font-weight);
}
.Tab + .Tab {
  margin-left: 2.5rem;
}

.Tab--Pulled {
  justify-content: flex-end;
  flex: 1;
}

.Tab__Content {
  padding-top: 1rem;
}

.table {
  background-color: var(--table-background);
  outline: none;
}
.table .table--checkbox {
  position: absolute;
  top: 30%;
  left: 1rem;
  width: 1rem;
  min-width: 1.125rem;
  max-width: 1.125rem;
  height: 1.125rem;
  min-height: 1.125rem;
  max-height: 1.125rem;
}
.table:focus {
  border: var(--table-focus-width) solid var(--table-focus-color);
}

.table,
.table__header__wrapper,
.table__row__wrapper {
  display: block;
}

.table__header,
.table__row,
.table__footer,
.table__cell,
.table__pagination,
.table__row__expand {
  display: flex;
}

.table__cell,
.table__pagination,
.table__row__expand {
  align-items: center;
}

.table__cell {
  flex: 1;
}

.table__top {
  display: grid;
  grid-template-rows: repeat(2, max-content);
  margin-top: 0.75rem;
}
@media (min-width: 48rem) {
  .table__top {
    grid-template-columns: 1fr max-content;
    grid-row-gap: 1.5rem;
    margin: 1.5rem 0;
  }
}
@media (min-width: 80rem) {
  .table__top {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr repeat(2, max-content);
    margin: 2rem 0 2.5rem;
  }
}

.table__top__title {
  grid-row: 1/2;
  align-self: center;
  padding: 0 1rem;
}
@media (min-width: 48rem) {
  .table__top__title {
    grid-column: 1/2;
    padding: 0;
  }
}

.table__top__filters {
  grid-row: 2/3;
  grid-column: 1/3;
}
@media (min-width: 80rem) {
  .table__top__filters {
    grid-row: 1/2;
    grid-column: 2/3;
  }
}

.table__top__filters--new-row {
  grid-row: 2/3;
  grid-column: 1/2;
}

.table__top__actions {
  display: none;
}
@media (min-width: 48rem) {
  .table__top__actions {
    display: block;
    grid-row: 1/2;
    grid-column: 2/3;
  }
}
@media (min-width: 80rem) {
  .table__top__actions {
    grid-column: 3/4;
    padding-top: 2rem;
    margin-left: 1.5rem;
  }
}

.table__actions--mobile,
.table__actions--mobile__menu {
  display: block;
  position: fixed;
  right: 1rem;
  bottom: 1rem;
}
@media (min-width: 48rem) {
  .table__actions--mobile,
  .table__actions--mobile__menu {
    display: none;
  }
}

.table__actions--mobile__toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--table-actions-mobile-toggle-background);
  border-radius: 50%;
  cursor: pointer;
  box-shadow: var(--card-shadow);
}
.table__actions--mobile__toggle .icon {
  color: var(--table-actions-mobile-toggle-icon);
}

.table__actions--mobile__menu {
  padding: 0.25rem 0;
  background-color: var(--white);
  border-radius: 0.25rem;
  z-index: var(--layer-modal);
}
.table__actions--mobile__menu .form-control__menu__option {
  padding: 0.5rem 1rem;
}

.table__cell--header {
  cursor: pointer;
}
.table__cell--header .button__group {
  margin-left: auto;
}
.table__cell--header .icon {
  margin-left: 0.5rem;
}

.table__footer {
  flex-direction: column;
  padding: 0 1rem;
  margin-top: 0.75rem;
}
@media (min-width: 48rem) {
  .table__footer {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
  }
}

.table__footer__count {
  margin-bottom: 0.5rem;
}
@media (min-width: 48rem) {
  .table__footer__count {
    margin: 0;
  }
}

.table__cell {
  padding: var(--table-cell-padding-y) var(--table-cell-padding-x);
  word-break: break-word;
}

.table__cell__actions {
  display: flex;
}
.table__cell__actions icon {
  padding: 0 0.5rem;
}
.table__cell__actions .icon + .icon {
  border-left: 1px solid var(--gray--border);
}

.table__pagination__button {
  padding: 0 0.5rem;
  background-color: var(--table-page-button-background);
  border: var(--table-page-button-border-width) solid var(--table-page-button-border-color);
  border-right: 0;
  cursor: pointer;
  outline: 0;
}
.table__pagination__button .t-bold {
  color: var(--table-page-button-active);
}
.table__pagination__button:first-of-type {
  border-top-left-radius: var(--table-page-button-border-radius);
  border-bottom-left-radius: var(--table-page-button-border-radius);
}
.table__pagination__button:last-of-type {
  border-right: var(--table-page-button-border-width) solid var(--table-page-button-border-color);
  border-top-right-radius: var(--table-page-button-border-radius);
  border-bottom-right-radius: var(--table-page-button-border-radius);
}
.table__pagination__button.is-disabled, .table__pagination__button:disabled {
  cursor: not-allowed;
}

.table__body {
  height: 100%;
  min-height: 3rem;
}
@media (min-width: 48rem) {
  .table__body {
    border: var(--table-body-border-width) solid var(--table-body-border-color);
    border-top: 0;
    border-bottom-right-radius: var(--table-body-border-radius);
    border-bottom-left-radius: var(--table-body-border-radius);
  }
}

.table__row__wrapper--nested .table__row {
  position: relative;
  width: 100%;
  padding-left: 2rem;
}

.table__row__wrapper--hover:hover {
  background-color: var(--gray--border);
}

.table__row__wrapper--group .table__row {
  padding-left: 1.5rem;
}

.table__header {
  position: relative;
}
.table__header .header--checkbox {
  top: 1rem;
}

.table__header__wrapper {
  background-color: var(--table-header-background);
}
@media (min-width: 48rem) {
  .table__header__wrapper {
    border: var(--table-header-border-width) solid var(--table-header-border-color);
    border-top-left-radius: var(--table-header-border-radius);
    border-top-right-radius: var(--table-header-border-radius);
  }
}

.table__header__wrapper--dark {
  background-color: var(--table-header-alt-background);
  border-color: var(--table-header-alt-border-color);
}
.table__header__wrapper--dark .icon,
.table__header__wrapper--dark .t-data {
  color: var(--table-header-alt-text-color);
}

.table__row,
.table__header {
  width: 100%;
  padding: 0 1rem;
}
@media (min-width: 48rem) {
  .table__row,
  .table__header {
    padding: 0 1.5rem;
  }
  .table__row.multiple-select,
  .table__header.multiple-select {
    padding-left: 2.5rem;
    margin: 0;
  }
}

.table__row__wrapper.is-expanded {
  border-left: 2px solid var(--text--1);
  box-shadow: 0 2px 8px 0 var(--gray--shadow);
}
.table__row__wrapper.is-expanded.table__row__wrapper--nested {
  border-left: 1px solid var(--gray--border);
  box-shadow: none;
}
.table__row__wrapper.is-expanded.table__row__wrapper--nested .t-data {
  font-weight: var(--heavy);
}
.table__row__wrapper:nth-of-type(odd) {
  background-color: var(--table-row-stripe-color);
}

.table__row {
  border-bottom: var(--table-row-border-width) solid var(--table-row-border-color);
}
.table__row.is-selected {
  background-color: var(--gray--inactive);
  box-shadow: inset 4px 0 0 var(--primary);
}
.table__row.is-selected .t-data {
  font-weight: var(--heavy);
}
.table__row:hover .table__row__indicator {
  opacity: 1;
}
.table__row.table__row-nodata {
  border-bottom: 0;
}
@media (min-width: 48rem) {
  .table__row {
    width: calc(100% - 3rem);
    padding: 0;
    margin: 0 1.5rem;
  }
}

.table__row--u-maxX {
  width: 100%;
}

.table__row__indicator {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  opacity: 0;
}
.table__row__indicator.is-active {
  opacity: 1;
}
.table__row__indicator.is-active .icon {
  color: var(--primary);
}

.table__row__details {
  padding-left: 3rem;
  background-color: var(--white);
  border-right: 1px solid var(--gray--border);
  border-left: 2px solid var(--text--1);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  box-shadow: 0 4px 8px 0 var(--gray--shadow);
}
@media (min-width: 48rem) {
  .table__row__details {
    padding-left: 3.5rem;
  }
}

.table__row__detail {
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--gray--border);
}
.table__row__detail:last-of-type {
  border-bottom: 0;
}
@media (min-width: 48rem) {
  .table__row__detail {
    padding: 0.75rem 0;
  }
}

.table__row__detail__label {
  margin-bottom: 0.125rem;
}
@media (min-width: 48rem) {
  .table__row__detail__label .t-caption {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.table--message .table__body {
  border-top: 1px solid var(--gray--border);
}
.table--message .table__cell {
  flex-direction: column;
  align-items: baseline;
  flex: initial;
}

.table__row .table__cell .status {
  width: auto;
}

.table__row .table__cell .status__text {
  white-space: pre-wrap;
}

.tag {
  padding: 0.25rem 0.75rem;
  background-color: rgba(var(--secondary--3), 0.1);
  border: 1px solid var(--secondary--3);
  border-radius: 1.25rem;
}

.toggle {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
}

.toggle__option {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--toggle-width-mobile);
  height: var(--toggle-height);
  padding: 0 var(--toggle-padding-x);
  border: var(--toggle-border-width) solid var(--toggle-border-color);
  cursor: pointer;
  font-size: var(--toggle-font-size);
  line-height: var(--toggle-line-height);
}
.toggle__option.is-active {
  background-color: var(--toggle-active-background);
  border-color: var(--toggle-active-border);
}
.toggle__option.is-active .t-data {
  color: var(--toggle-active-text-color);
  font-weight: var(--toggle-active-font-weight);
}
.toggle__option.is-active[readonly] {
  background-color: var(--toggle-disabled-active-background);
}
.toggle__option.is-active[readonly] .t-data {
  color: var(--toggle-disabled-active-text-color);
  font-weight: var(--toggle-disabled-active-font-weight);
}
.toggle__option:first-of-type {
  border-right: 0;
  border-top-left-radius: var(--toggle-border-radius);
  border-bottom-left-radius: var(--toggle-border-radius);
}
.toggle__option:last-of-type {
  border-top-right-radius: var(--toggle-border-radius);
  border-bottom-right-radius: var(--toggle-border-radius);
}
.toggle__option[readonly] {
  background-color: unset;
}
.toggle__option[readonly] .t-data {
  color: var(--toggle-disabled-text-color);
}
@media (min-width: 48rem) {
  .toggle__option {
    min-width: var(--toggle-width-tablet);
  }
}

.tooltip {
  display: flex;
  position: relative;
  cursor: pointer;
  outline: 0;
}
.tooltip::before, .tooltip::after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transition: opacity var(--transition-timing--slow) ease, transform var(--transition-timing--slow) ease;
  z-index: var(--layer-fixed);
  opacity: 0;
  pointer-events: none;
}
.tooltip::before {
  content: attr(data-tooltip);
  width: -moz-max-content;
  width: max-content;
  max-width: 15rem;
  padding: 0.75rem;
  color: var(--white);
  background-color: var(--tooltip-background);
  border-radius: var(--tooltip-border-radius);
  transform: translate3d(-50%, -1.25rem, 0);
  font-size: var(--tooltip-font-size);
  font-weight: var(--tooltip-font-weight);
  line-height: var(--tooltip-line-height);
  text-transform: none;
  letter-spacing: var(--tooltip-letter-spacing);
}
.tooltip::after {
  content: "";
  width: 0;
  height: 0;
  border-top: 0.5rem solid var(--tooltip-background);
  border-right: 0.5rem solid var(--transparent);
  border-left: 0.5rem solid var(--transparent);
  transform: translate3d(-50%, -0.75rem, 0);
}
.tooltip:focus::before, .tooltip:focus::after, .tooltip:hover::before, .tooltip:hover::after {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .tooltip:focus::before, .tooltip:focus::after, .tooltip:hover::before, .tooltip:hover::after {
    left: 0;
  }
}
.tooltip:focus::before, .tooltip:hover::before {
  transform: translate3d(-50%, -0.75rem, 0);
}
.tooltip:focus::after, .tooltip:hover::after {
  transform: translate3d(-50%, -0.25rem, 0);
}

:root {
  font-variant-numeric: var(--font-variant-numeric, unset);
}

body {
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga" 1;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

/* Color */
body,
input,
select,
textarea,
a,
p {
  color: var(--text--1);
  font-family: var(--typeface--1);
  /*
      important note: var(--typeface-1-name) gets injected into the app by design system from file _type.scss
      this can cause issues with the fonts changing in the middle of app operation
      to avoid this issue make sure --typeface--1 and --typeface-1-name are the same font family in tenant configs
  */
}

button {
  color: var(--text--1);
  font-family: var(--typeface--2, var(--typeface-1-name, var(--typeface--1))) !important;
  /* similar issue as above, applying important to prevent this change... -hlopez */
}

/* Cursor & text decoration */
a {
  cursor: pointer;
  text-decoration: none;
}

/* Transition */
a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  transition: color var(--transition-timing) ease;
}

/* Font weight */
p {
  font-weight: var(--regular);
}

/* Headings */
h1 {
  font-family: var(--h1-font-family, var(--typeface--1), var(--typeface--2), unset);
  font-size: var(--h1-font-size, unset);
  line-height: var(--h1-line-height, unset);
  font-weight: var(--h1-font-weight, var(--regular), unset);
  color: var(--h1-text-color, var(--text--1), unset);
}

h2 {
  font-family: var(--h2-font-family, var(--typeface--1), var(--typeface--2), unset);
  font-size: var(--h2-font-size, unset);
  line-height: var(--h2-line-height, unset);
  font-weight: var(--h2-font-weight, var(--regular), unset);
  color: var(--h2-text-color, var(--text--1), unset);
}

h3 {
  font-family: var(--h3-font-family, var(--typeface--1), var(--typeface--2), unset);
  font-size: var(--h3-font-size, unset);
  line-height: var(--h3-line-height, unset);
  font-weight: var(--h3-font-weight, var(--regular), unset);
  color: var(--h3-text-color, var(--text--1), unset);
}

h4 {
  font-family: var(--h4-font-family, var(--typeface--1), var(--typeface--2));
  font-size: var(--h4-font-size, unset);
  line-height: var(--h4-line-height, unset);
  font-weight: var(--h4-font-weight, var(--regular), unset);
  color: var(--h4-text-color, var(--text--1), unset);
}

h5 {
  font-family: var(--h5-font-family, var(--typeface--1), var(--typeface--2), unset);
  font-size: var(--h5-font-size, unset);
  line-height: var(--h5-line-height, unset);
  font-weight: var(--h5-font-weight, var(--regular), unset);
  color: var(--h5-text-color, var(--text--1), unset);
}

h6 {
  font-family: var(--h6-font-family, var(--typeface--1), var(--typeface--2));
  font-size: var(--h6-font-size, unset);
  line-height: var(--h6-line-height, unset);
  font-weight: var(--h6-font-weight, var(--regular));
  color: var(--h6-text-color, var(--text--1), unset);
}

@media (min-width: 48rem) {
  h1 {
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
  }
}

@media (min-width: 48rem) {
  h2 {
    font-size: var(--h2-font-size);
    line-height: var(--h2-line-height);
  }
}

@media (min-width: 48rem) {
  h3 {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
  }
}

@media (min-width: 48rem) {
  h4 {
    font-size: var(--h4-font-size);
    line-height: var(--h4-line-height);
  }
}

@media (min-width: 48rem) {
  h5 {
    font-size: var(--h5-font-size);
    line-height: var(--h5-line-height);
  }
}

@media (min-width: 48rem) {
  h6 {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
  }
}

p,
a {
  font-size: var(--a-p-font-size);
  line-height: var(--a-p-line-height);
}

.t-body,
.t-data {
  font-size: var(--t-data-font-size);
  line-height: var(--t-data-line-height);
}

.t-body {
  font-family: var(--typeface--2);
}

.t-caption {
  font-size: var(--t-caption-font-size);
  line-height: var(--t-caption-line-height);
}

.t-bullet {
  font-size: var(--t-bullet-font-size);
  line-height: var(--t-bullet-line-height);
}

.t-pointer {
  cursor: pointer;
}

.t-link {
  color: var(--link);
  cursor: pointer;
  font-weight: var(--link-font-weight, unset);
  -webkit-text-decoration: var(--link-text-decoration, unset);
          text-decoration: var(--link-text-decoration, unset);
  -webkit-text-decoration-color: var(--link-text-decoration-color, unset);
          text-decoration-color: var(--link-text-decoration-color, unset);
}
.t-link:hover {
  text-decoration: underline;
}
.t-link[readonly] {
  color: var(--primary--highlight);
}

.t-primary {
  color: var(--primary);
}

.t-error {
  color: var(--error);
}

.t-success {
  color: var(--success);
}

.t-bold,
.t-heavy {
  font-weight: var(--t-bold-font-weight);
}

.t-alt {
  color: var(--text--2);
}

.t-strikethrough {
  text-decoration: line-through;
}

.t-caps {
  text-transform: uppercase;
  letter-spacing: var(--t-caps-letter-spacing);
}

.t-capitalize {
  text-transform: capitalize;
}

.t-super {
  font-size: 0.375rem;
  vertical-align: super;
}

.t-underline {
  text-decoration: underline;
}

.t-alignCenter {
  text-align: center;
}

.t-alignRight {
  text-align: right;
}

@media (min-width: 48rem) {
  .PlanSelection--Mobile {
    display: none;
  }
}

.layout-template-detail-1 .layout--desktop--detail--link {
  border-bottom: 1px solid var(--gray--border);
}

.layout--desktop--detail--section h6 {
  border-bottom: 1px solid var(--gray--border);
}

.layout--desktop--stepper--forms--footer,
.layout--desktop--detail--footer {
  background-color: var(--white);
  box-shadow: 0 0 0.25rem 0 var(--gray--shadow);
}

.layout--desktop--stepper--forms--footer .form-control__wrapper {
  padding: 0;
  margin: 1.25rem 0 0 3.25rem;
}

.layout--stepper--form--label--container .layout--stepper--form--label {
  border-top: 1px solid var(--gray--border);
}
.layout--stepper--form--label--container .layout--stepper--form--label:first-of-type {
  border-top: 0;
}

.layout-template-stepper-forms-2 .layout--mobile {
  background-color: var(--gray--inactive);
}

.error--section {
  border-bottom: 1px solid var(--gray--shadow);
}

.sections .section .underline {
  background-color: var(--text--1);
}

.dashboard::after {
  background-color: var(--dashboard--bg-color, var(--gray--inactive));
}

.dashboard__aside {
  border-right: 1px solid var(--gray--border);
}

.dashboard__aside__section + .dashboard__aside__section {
  border-top: 1px solid var(--gray--border);
}

.dashboard__main {
  background-color: var(--dashboard--bg-color, var(--gray--inactive));
}

.dashboard__card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}

.dashboard__card__avatar {
  background-color: var(--gray--border);
}

.dashboard__card__footer {
  width: calc(100% - 3rem);
  margin: 0.75rem auto 0;
  border-top: 1px solid var(--gray--border);
}
.dashboard__card__footer .t-link + .t-link {
  border-left: 1px solid var(--gray--border);
}

.dashboard__card--scroll .dashboard__card__footer {
  border-top: 0;
  box-shadow: var(--footer-shadow);
}

.dashboard__notification:last-of-type .dashboard__notification__content {
  border-bottom: 0;
}

.dashboard__notification__content {
  border-bottom: 1px solid var(--gray--border);
}

.dashboard__card__indicator.is-success {
  background-color: var(--success);
}
.dashboard__card__indicator.is-warn {
  background-color: var(--warn);
}
.dashboard__card__indicator.is-error {
  background-color: var(--error);
}

.dashboard__card__icon {
  border: 1px solid var(--primary);
}
.dashboard__card__icon .icon {
  color: var(--primary);
}

.layout--detail {
  background-color: var(--white);
}
.layout--detail::before {
  background-color: var(--gray--inactive);
}
.layout--detail::after {
  background-color: var(--white);
}

.layout--detail--alt {
  background-image: var(--layout--detail-alt, url("https://d32ul9oyxvd2n5.cloudfront.net/assets/stripe.svg"));
  background-repeat: repeat-y;
  background-size: 100%;
}
.layout--detail--alt::before,
.layout--detail--alt .layout--detail__aside {
  background-color: rgba(var(--gray--artboard), 0.75);
}
.layout--detail--alt .layout--detail__aside {
  border-right: 1px solid var(--gray--border);
}

.layout--detail__aside {
  background-color: var(--gray--inactive);
}

.layout--detail__aside__header,
.LayoutDetails__AsideUser,
.layout--detail__main__section__header {
  border-bottom: 1px solid var(--gray--border);
}

.layout--detail__aside__section + .layout--detail__aside__section {
  border-top: 1px solid var(--gray--border);
}

.layout--detail__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.inner-card {
  background: rgba(var(--gray--inactive), 0.75);
}

.inner-card__header {
  border-bottom: 1px solid var(--gray--shadow);
  font-weight: var(--heavy);
  text-transform: uppercase;
}
.inner-card__header .close {
  color: var(--error);
}

.inner-card__footer {
  border-top: 1px solid var(--gray--shadow);
  font-weight: var(--heavy);
  text-transform: uppercase;
}

.layout--stepper {
  grid-template-rows: calc(100vh - 3.75rem);
}
.layout--stepper::before {
  background-color: var(--white);
}
.layout--stepper::after {
  background-color: var(--gray--inactive);
}

.layout--stepper__content {
  background-color: var(--gray--inactive);
}

.layout--stepper__main--solid {
  background: var(--white);
}

.layout--stepper__aside {
  background-color: var(--white);
  border-right: 1px solid var(--gray--border);
}

.layout--stepper__aside__header,
.layout--stepper__group__wrapper {
  border-bottom: 1px solid var(--gray--border);
}

.layout--stepper__group {
  border-radius: 0.25rem;
}
.layout--stepper__group.is-disabled, .layout--stepper__group.disabled {
  cursor: default;
}
.layout--stepper__group.is-visited .t-caps {
  color: var(--primary);
}
.layout--stepper__group.is-active .t-data {
  font-weight: var(--heavy);
}
.layout--stepper__group.is-active .layout--stepper__group__indicator, .layout--stepper__group.is-complete .layout--stepper__group__indicator {
  background-color: var(--text--1);
}
.layout--stepper__group.is-active .layout--stepper__group__indicator .t-data,
.layout--stepper__group.is-active .layout--stepper__group__indicator .icon, .layout--stepper__group.is-complete .layout--stepper__group__indicator .t-data,
.layout--stepper__group.is-complete .layout--stepper__group__indicator .icon {
  color: var(--white);
}
.layout--stepper__group.has-subsection {
  margin-bottom: 0.5rem;
}

.layout--stepper__subgroup.is-active {
  background: var(--gray--border);
}

.layout--stepper__main .form-group {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}

.layout--stepper__main__content {
  background: var(--white);
}

.layout--stepper__group__indicator {
  width: 1.375rem;
  min-width: 1.375rem;
  max-width: 1.375rem;
  height: 1.375rem;
  min-height: 1.375rem;
  max-height: 1.375rem;
}

.layout--stepper__group {
  border-bottom: 1px solid var(--gray--border);
}
.layout--stepper__group:last-of-type {
  border: 0;
}

.layout--stepper__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.layout--stepper--alt {
  background-image: var(--layout-stepper-bg-img, url("https://d32ul9oyxvd2n5.cloudfront.net/assets/stripe.svg"));
  background-repeat: repeat-y;
  background-size: 100%;
}
.layout--stepper--alt::after,
.layout--stepper--alt .layout--stepper__content {
  background-color: var(--transparent);
}
.layout--stepper--alt .layout--stepper__aside {
  border-right: 1px solid var(--gray--border);
}

.inner-card {
  background: rgba(var(--gray--inactive), 0.75);
}

.inner-card__header {
  border-bottom: 1px solid var(--gray--shadow);
  font-weight: var(--heavy);
  text-transform: uppercase;
}
.inner-card__header .close {
  color: var(--error);
}

.inner-card__footer {
  border-top: 1px solid var(--gray--shadow);
  font-weight: var(--heavy);
  text-transform: uppercase;
}

.workflow-step__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.add-row-attribute {
  margin: 0.25rem 0;
}

.add-row-attribute__title {
  border-bottom: 1px solid var(--gray--border);
  padding: 1rem 0;
  margin-bottom: 1rem;
}
.add-row-attribute__title + .add-row-attribute__title {
  margin-top: 2rem;
}

.mobile-menu {
  background-color: var(--white);
}

.mobile-menu--alt .mobile-menu__header {
  background-color: var(--white);
  box-shadow: var(--card-shadow);
}
.mobile-menu--alt .mobile-menu__header .icon,
.mobile-menu--alt .mobile-menu__header .t-data {
  color: var(--text--1);
}

.mobile-menu__option:hover .icon--pointer, .mobile-menu__option.is-active .icon--pointer {
  color: var(--primary);
}

.mobile-menu__header {
  background-color: var(--primary--highlight);
}
.mobile-menu__header .icon,
.mobile-menu__header .t-data {
  color: var(--white);
}

.mobile-menu__body {
  height: calc(100vh - 7rem);
}

.mobile-menu__footer {
  background-color: var(--white);
}

.mobile-menu__section + .mobile-menu__section {
  padding-top: 0.5rem;
  margin-top: 0.5rem;
  border-top: 1px solid var(--gray--border);
}

.mobile-menu__toolbar {
  background-color: var(--gray--inactive);
}

.nav__panel__header {
  background-color: var(--white);
  box-shadow: var(--card-shadow);
}
.nav__panel__header .icon,
.nav__panel__header .t-data {
  color: var(--text--1);
}

.mobile-menu__item:hover, .mobile-menu__item.is-open {
  background-color: var(--gray--inactive);
}
.mobile-menu__item.is-active {
  background-color: var(--gray--border);
}
.mobile-menu__item.is-active .t-data {
  font-weight: var(--heavy);
}

.mobile-menu__subitems {
  background-color: var(--white);
}

.tablet-toolbar {
  background-color: var(--primary--highlight);
}

.tablet-toolbar__contact .t-data {
  color: var(--white);
}

.toolbar {
  background-color: var(--toolbar-bg-color, var(--primary--highlight));
  color: var(--toolbar-color, var(--white));
  border-bottom: var(--toolbar-border-bottom, unset);
  min-height: var(--toolbar-min-height, unset);
}

.toolbar__tool {
  color: var(--nav-toolbar-tool, var(--white));
}
.toolbar__tool .icon {
  color: inherit;
}
.toolbar__tool:hover {
  background-color: var(--nav-toolbar-tool-hover, var(--text--1));
}

.toolbar__dropdown__toggle,
.toolbar__dropdown__menu__item,
.toolbar__tool {
  transition: background-color var(--transition-timing) ease;
}

.toolbar__dropdown__toggle {
  background-color: var(--text--1);
}
.toolbar__dropdown__toggle .t-data,
.toolbar__dropdown__toggle .icon {
  color: var(--white);
}
.toolbar__dropdown__toggle:hover, .toolbar__dropdown__toggle.is-open {
  background-color: var(--text--1);
}
.toolbar__dropdown__toggle.is-open .icon {
  transform: rotate(180deg);
}

.toolbar__dropdown__menu {
  background-color: var(--white);
  box-shadow: var(--card-shadow);
  border-radius: 0.25rem;
  z-index: var(--toolbar-z-index, 100);
}

.toolbar__dropdown__menu__item .icon {
  color: var(--text--2);
}
.toolbar__dropdown__menu__item.is-active .icon,
.toolbar__dropdown__menu__item.is-active .t-alt {
  color: var(--text--1);
}
.toolbar__dropdown__menu__item.is-active .t-alt {
  font-weight: var(--heavy);
}
.toolbar__dropdown__menu__item:hover {
  background-color: var(--gray--inactive);
}

.nav__logo {
  width: var(--nav-logo-width, var(--logo--width));
  height: var(--logo--height);
  min-width: var(--logo--width);
  margin: 0.75rem 1.5rem 0.8125rem 0;
  background-image: var(--logo--dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}
@media (min-width: 48rem) {
  .nav__logo {
    background-image: var(--logo--light);
  }
}
@media (min-width: 80rem) {
  .nav__logo {
    background-image: var(--logo--dark);
  }
}

.nav__tagline .t-data {
  color: var(--white);
}

.nav {
  background-color: var(--nav-bg-color, var(--primary--highlight));
  border-bottom: var(--nav-border-bottom, 2px solid var(--gray--inactive));
}
@media (min-width: 48rem) {
  .nav {
    background-color: var(--white);
    box-shadow: var(--card-shadow);
  }
}
@media (min-width: 80rem) {
  .nav {
    background-color: var(--nav-bg-color, var(--primary--highlight));
    box-shadow: none;
  }
}

@media (min-width: 48rem) {
  .nav--alt {
    background-color: var(--nav-background, var(--white));
    box-shadow: var(--card-shadow);
  }
  .nav--alt .nav__toggle .icon {
    color: var(--text--1);
  }
  .nav--alt .nav__logo {
    background-image: var(--logo--light);
  }
  .nav--alt .nav__link {
    background-color: var(--nav-background, var(--white));
    min-height: 3.125rem;
  }
  .nav--alt .nav__link .t-data,
  .nav--alt .nav__link .icon {
    color: var(--nav-link-color, var(--text--1));
  }
  .nav--alt .nav__link:hover, .nav--alt .nav__link.is-open, .nav--alt .nav__link.is-active {
    background-color: var(--nav-link-background-active, var(--gray--inactive));
  }
}

.nav__toggle .icon {
  color: var(--white);
}
@media (min-width: 48rem) {
  .nav__toggle .icon {
    color: var(--text--1);
  }
}
@media (min-width: 80rem) {
  .nav__toggle .icon {
    color: var(--white);
  }
}

.nav__link {
  padding: 0 1.5rem;
  transition: background-color var(--transition-timing) ease;
}
.nav__link .t-data,
.nav__link .icon {
  color: var(--nav-link-color, var(--white));
}
.nav__link:hover, .nav__link.is-open {
  background-color: var(--primary);
}
.nav__link.is-active {
  background-color: var(--nav-link-background-active, var(--text--1));
}
.nav__link.is-active .t-data {
  font-weight: var(--nav-link-font-weight, unset);
}
@media (min-width: 48rem) {
  .nav__link {
    background-color: var(--white);
  }
  .nav__link .t-data,
  .nav__link .icon {
    color: var(--text--1);
  }
  .nav__link:hover, .nav__link.is-open, .nav__link.is-active {
    background-color: var(--gray--inactive);
  }
}
@media (min-width: 80rem) {
  .nav__link {
    background-color: var(--transparent);
    transition: background-color var(--transition-timing) ease;
  }
  .nav__link .t-data,
  .nav__link .icon {
    color: var(--white);
  }
  .nav__link:hover, .nav__link.is-open {
    background-color: var(--primary);
  }
  .nav__link.is-active {
    background-color: var(--nav-link-background-active, var(--text--1));
  }
  .nav__link.is-active .t-data {
    font-weight: var(--nav-link-font-weight, unset);
  }
}

.nav__link__menu {
  padding: 0.5rem 0;
  background-color: var(--white);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}
.nav__link__menu .t-data,
.nav__link__menu .icon {
  color: var(--text--1);
}
.nav__link__menu .t-alt {
  color: var(--text--2);
}

.nav__link__menu__item:hover {
  background-color: var(--gray--inactive);
}
.nav__link__menu__item:hover .t-bold,
.nav__link__menu__item:hover .icon {
  color: var(--primary);
}

.nav__icons .nav__link {
  padding: 0 0.75rem;
}

.universal__shopping__footer {
  background-color: var(--footer-background-color, var(--gray--jet));
}

.universal__shopping__sub__footer {
  background-color: var(--sub-footer-background-color, var(--white));
}

.universal__shopping__footer__content h5,
.universal__shopping__footer__content p,
.universal__shopping__footer__content .t-link,
.universal__shopping__footer__content .icon {
  color: var(--footer-text-color, var(--white));
}

.plan-filters__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px var(--gray--shadow);
}

.layout--messages__header::after {
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.layout__user-img {
  background: var(--gray--shadow);
}

.layout--messages {
  border: 0;
}
.layout--messages::placeholder {
  color: var(--black);
}

.layout--message {
  border-bottom: 1px solid var(--gray--border);
}

.layout--fixed {
  background: var(--white);
}

.layout--messages__subheader {
  border-bottom: 1px solid var(--gray--border);
}

.layout__new-message {
  background: var(--secondary--1);
}
.layout__new-message .icon {
  color: var(--white);
}

.layout__filter-menu,
.layout__menu {
  background: var(--primary--highlight);
}
.layout__filter-menu .icon,
.layout__filter-menu p,
.layout__menu .icon,
.layout__menu p {
  color: var(--white);
}

.layout__filters {
  border-bottom: 1px solid var(--gray--border);
}

.layout__reply {
  background: var(--white);
}

@media (min-width: 80rem) {
  .layout--messages__header::after {
    box-shadow: none;
  }
  .layout__filters {
    border-bottom: 0;
  }
  .message__confirmation {
    background: var(--gray--inactive);
  }
}
.notifications {
  background-color: var(--white);
}

.notification.is-read .notification__timeline {
  background-color: var(--gray--border);
}
.notification.is-read .notification__icon {
  background-color: var(--gray--border);
}
.notification.is-read .notification__icon .icon {
  color: var(--text--2);
}
.notification.is-read .notification__pointer {
  border-right-color: var(--gray--border);
}
.notification.is-read .notification__content {
  background-color: var(--gray--border);
}
.notification.is-read .notification__content .t-data,
.notification.is-read .notification__content .icon {
  color: var(--text--2);
}
.notification.is-read .notification__content::before {
  background-color: var(--gray--border);
}
.notification.is-open .notification__pointer {
  filter: drop-shadow(0 2px 4px var(--gray--shadow));
}
.notification.is-open .notification__content {
  box-shadow: var(--card-shadow);
}

.notification__timeline {
  background-color: var(--text--1);
}

.notification__icon {
  background-color: var(--text--1);
  transition: background-color var(--transition-timing) ease;
}
.notification__icon .icon {
  color: var(--white);
}

.notification__content {
  background-color: var(--white);
  transition: box-shadow var(--transition-timing) ease;
}
.notification__content::before {
  background-color: var(--white);
}

.notification__pointer {
  border-top: 0.625rem solid var(--transparent);
  border-right: 0.625rem solid var(--white);
  border-bottom: 0.625rem solid var(--transparent);
  transition: border-right-color var(--transition-timing) ease, filter var(--transition-timing) ease;
}

.prospect-detail__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}

.prospect-detail__accordion {
  border-bottom: 1px solid var(--gray--border);
}

.prospects-modal__header,
.prospects-modal__section__header {
  border-bottom: 1px solid var(--gray--border);
}

.button__options {
  box-shadow: 0 1px 1px var(--gray--shadow);
}
.button__options .button__option:hover {
  background-color: var(--gray--inactive);
}

/** nested these classes for specificity over the normal button in main breadcrumb */
.layout--detail__quote-details .layout--detail__main__breadcrumb {
  justify-content: flex-end;
}
.layout--detail__quote-details .layout--detail__main__breadcrumb button {
  max-width: unset;
}
.layout--detail__quote-details .layout--detail__main__breadcrumb div:first-child {
  flex-basis: 100%;
}

/** nested these classes for specificity over the normal button in main breadcrumb */
.layout--detail__application-details .layout--detail__main__breadcrumb {
  justify-content: flex-end;
}
.layout--detail__application-details .layout--detail__main__breadcrumb button {
  max-width: unset;
}
.layout--detail__application-details .layout--detail__main__breadcrumb div:first-child {
  flex-basis: 100%;
}

.button__options {
  box-shadow: 0 1px 1px var(--gray--shadow);
}
.button__options .button__option:hover {
  background-color: var(--gray--inactive);
}

/** nested these classes for specificity over the normal button in main breadcrumb */
.layout--detail__policy-details .layout--detail__main__breadcrumb button {
  max-width: unset;
}

.button__options {
  box-shadow: 0 1px 1px var(--gray--shadow);
}
.button__options .button__option:hover {
  background-color: var(--gray--inactive);
}

.layout--detail__client-details .layout--detail__main__breadcrumb button {
  max-width: unset;
}

grid {
  background-color: var(--gray--inactive);
}

.ReviewStepper {
  background-color: var(--gray--inactive);
}

.ReviewStepper__Cards .ReviewStepper__CardBodySection {
  border-top: 1px solid var(--gray--border);
}
.ReviewStepper__Cards .ReviewStepper__CardHeader {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid var(--gray--border);
}
.ReviewStepper__Cards model-attribute .model-attribute__label p {
  font-weight: 900;
}
.ReviewStepper__Cards model-attribute .model-attribute__label p.t-caption {
  font-size: 1rem;
}
.ReviewStepper__Cards model-attribute .model-attribute__value p {
  font-weight: 400;
}

.ReviewStepperFooter {
  box-shadow: 0 0 0.25rem 0 var(--gray--shadow);
}

.Typeahead--icon-close {
  top: 45% !important;
}

.layout--detail--color::before {
  background-color: var(--white);
}
.layout--detail--color::after {
  background-color: var(--white);
}

.group__header--color {
  background-color: var(--gray--inactive);
}

.Tabs {
  padding: 0 1rem;
}

/* lines of business */
.enrollment {
  background-color: var(--white);
}

.small-group-enrollment .enrollment__exit .icon,
.small-group-enrollment .enrollment__exit .t-data {
  color: var(--primary);
}

.enrollment__header {
  background-color: var(--white);
  border-top: 1px solid var(--gray--border);
  border-bottom: 1px solid var(--gray--border);
}

.enrollment__header__step .icon {
  color: var(--gray--shadow);
}
.enrollment__header__step.is-visited .t-alt,
.enrollment__header__step.is-visited .icon {
  color: var(--primary);
}
.enrollment__header__step.is-active .t-alt {
  color: var(--text--1);
  font-weight: var(--heavy);
}

.enrollment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}
.enrollment__footer .form-control {
  margin-right: 0.5rem;
}

.census__aside {
  border-right: 1px solid var(--gray--border);
}

.census__aside__section + .census__aside__section {
  border-top: 1px solid var(--gray--border);
}

.census__badge {
  border-left: 4px solid var(--transparent);
  border-radius: 0.25rem;
}

.census__badge--new {
  background-color: var(--gray--inactive);
  border-left-color: var(--primary);
}

.census__main__header {
  background-color: var(--gray--inactive);
  border: 1px solid var(--gray--border);
  border-radius: 0.25rem;
}

.census .banner {
  background-color: var(--gray--inactive);
}

.document-upload__document {
  border-bottom: 1px solid var(--gray--border);
}

.employee-info__header,
.employee-info__footer {
  background: var(--white);
  box-shadow: var(--card-shadow);
}

.individual-rate,
.individual-rate__detail {
  background-color: var(--gray--inactive);
}

.individual-rate__detail__header {
  background-color: var(--white);
}

.individual-rate__detail__plan {
  border-bottom: 1px solid var(--gray--border);
}
.individual-rate__detail__plan:last-of-type {
  border: 0;
}

.document-upload__document {
  border-bottom: 1px solid var(--gray--border);
}

.payment__header {
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__form-group {
  border-top: 1px solid var(--gray--inactive);
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}

.payment--card {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.accordion--card,
.review--card {
  margin: 0 auto;
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.model-attributes__group {
  border-top: 1px solid var(--gray--border);
}
.model-attributes__group.no-border {
  border-top: 0;
}

.model__attribute_plan-display + .model__attribute_plan-display {
  border-top: 1px solid var(--gray--border);
}

.attestation--confirm,
.layout--review {
  background-color: var(--gray--inactive);
}

.attestation--confirm,
.attestation--container {
  border: 1px solid var(--gray--border);
}

.form-control--textarea {
  height: var(--fc-textarea-height);
}

.enrollment {
  background-color: var(--white);
}

.create-quote .enrollment__exit .icon,
.create-quote .enrollment__exit .t-data {
  color: var(--primary);
}

.enrollment__header {
  background-color: var(--white);
  border-top: 1px solid var(--gray--border);
  border-bottom: 1px solid var(--gray--border);
}

.enrollment__header__step .icon {
  color: var(--gray--shadow);
}
.enrollment__header__step.is-visited .t-alt,
.enrollment__header__step.is-visited .icon {
  color: var(--primary);
}
.enrollment__header__step.is-active .t-alt {
  color: var(--text--1);
  font-weight: var(--heavy);
}

.enrollment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}
.enrollment__footer .form-control {
  margin-right: 0.5rem;
}

.payment__header {
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__form-group {
  border-top: 1px solid var(--gray--inactive);
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}

.payment--card {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.enrollment {
  background-color: var(--white);
}
.enrollment .enrollment__exit .icon,
.enrollment .enrollment__exit .t-data {
  color: var(--nav-icon-color);
}

.enrollment__exit .icon,
.enrollment__exit .t-data {
  color: var(--nav-icon-color);
}

.enrollment__header {
  background-color: var(--white);
  border-top: 1px solid var(--gray--border);
  border-bottom: 1px solid var(--gray--border);
}

.enrollment__header__step .icon {
  color: var(--gray--shadow);
}
.enrollment__header__step.is-visited .t-alt,
.enrollment__header__step.is-visited .icon {
  color: var(--primary);
}
.enrollment__header__step.is-active .t-alt {
  color: var(--text--1);
  font-weight: var(--heavy);
}

.enrollment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}
.enrollment__footer .form-control {
  margin-right: 0.5rem;
}

.enrollment__footer {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0.5rem 0;
}

.payment__header {
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__form-group {
  border-top: 1px solid var(--gray--inactive);
  border-bottom: 1px solid var(--gray--inactive);
}

.payment__footer {
  background-color: var(--white);
  box-shadow: 0 0 4px 0 var(--gray--shadow);
}

.payment--card {
  background-color: var(--white);
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.broker-portal {
  grid-template-rows: var(--broker-portal-grid-template-rows, 3.75rem 1fr);
  --header-height: 3.75rem;
}
@media (min-width: 48rem) and (max-width: 79.9375rem) {
  .broker-portal {
    grid-template-rows: var(--broker-portal-grid-template-rows, 6.25rem 1fr);
    --header-height: 6.25rem;
  }
}
.broker-portal .login-register,
.broker-portal .forgot-password {
  margin-top: 5rem;
}

shopping-application-details {
  margin-top: 0;
}

.plan__card__banner {
  background-color: var(--secondary--3);
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.plan__card__banner::before {
  border-width: 13px 0 13px 8px;
  border-color: transparent transparent transparent var(--white);
}
@media (min-width: 48rem) {
  .plan__card__banner {
    border-radius: 0;
  }
}

.plan__card__banner--primary {
  background-color: var(--secondary--1);
}
.plan__card__banner--primary .icon,
.plan__card__banner--primary .t-caption {
  color: var(--white);
}
.plan__card__banner--primary::after {
  border-width: 6px 8px 0 0;
  border-color: var(--gray--jet) transparent transparent;
}
@media (min-width: 48rem) {
  .plan__card__banner--primary {
    transform: translateX(0.5rem);
  }
}

@media (min-width: 48rem) {
  .PlanCard__Prices {
    border-bottom: 1px solid var(--gray--border);
  }
}

.plan__card__attribute + .plan__card__attribute {
  border-top: 1px solid var(--gray--border);
}
@media (min-width: 48rem) {
  .plan__card__attribute + .plan__card__attribute {
    border: 0;
  }
}

.plan__card__panel {
  background-color: var(--white);
}

.plan__card__panel__header {
  box-shadow: var(--card-shadow);
}

.PlanCard__Footer {
  background-color: var(--gray--inactive);
}

.billing {
  background-color: var(--gray--inactive);
}

.billing__card__footer {
  border-top: 1px solid var(--gray--border);
}

.billing__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.plan__summary {
  border-top: 1px solid var(--gray--border);
}

.display__error {
  border-bottom: 1px solid var(--gray--border);
}
.display__error p {
  font-weight: var(--heavy);
}

.compare-plans__header {
  box-shadow: var(--card-shadow);
}

.compare-plans__cell {
  background-color: var(--white);
  border-left: 1px solid var(--gray--border);
}
.compare-plans__cell:first-of-type {
  border-left: 0;
}
.compare-plans__cell .icon {
  color: var(--primary);
}

.compare-plans__section {
  border-bottom: 1px solid var(--gray--border);
}

.compare-plans__footer {
  box-shadow: var(--footer-shadow);
}

.confirmation {
  background-color: var(--gray--inactive);
}

.confirmation__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.confirmation__section + .confirmation__section {
  border-top: 1px solid var(--gray--border);
}

.personal-info {
  background-color: var(--personal-info-bg-color, var(--gray--inactive));
}

.personal-info__card__footer {
  border-top: 1px solid var(--gray--border);
}

.personal-info__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

personal-info .tooltip::before {
  margin-left: 4rem;
}

.personalize {
  background-color: var(--gray--inactive);
}

.sep__header,
.personalize__footer {
  background-color: var(--white);
}

.sep__header {
  border-bottom: 1px solid var(--gray--shadow);
}
@media (min-width: 48rem) {
  .sep__header .t-caption {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.sep__header__item .icon {
  color: var(--text--2);
}
.sep__header__item.is-active .icon,
.sep__header__item.is-active .t-alt {
  color: var(--primary);
}
.sep__header__item.is-active .sep__header__divider {
  background-color: var(--primary);
}

.sep__header__divider {
  background-color: var(--text--2);
}

.personalize__prompt__instruction {
  background-color: var(--gray--jet);
  border-radius: 0.25rem;
}
.personalize__prompt__instruction .t-data {
  color: var(--white);
}

.personalize__card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}
.personalize__card svg {
  fill: var(--primary);
}

.personalize__card__title h6 {
  background-color: var(--white);
}

.personalize__card__title__divider {
  border-top: 1px solid var(--gray--border);
}

.personalize__footer {
  box-shadow: var(--footer-shadow);
}

.personalize__skip button {
  width: 100%;
}

.recommended-plans {
  background-color: var(--gray--inactive);
}

.recommended-plans__header {
  background-color: var(--white);
  border-bottom: 1px solid var(--gray--border);
}
@media (min-width: 48rem) {
  .recommended-plans__header .t-caption {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.recommended-plans__header__action:hover {
  background-color: var(--gray--inactive);
}
.recommended-plans__header__action:hover .t-data,
.recommended-plans__header__action:hover .icon {
  color: var(--primary);
}

.recommended-plans__header__action + .recommended-plans__header__action {
  border-left: 1px solid var(--gray--border);
}

.recommended-plans__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.recommended-plans__options button {
  width: 100%;
}

.review__card plan-card {
  display: block;
  margin-top: -1.5rem;
}

.review__card__header {
  border-bottom: 1px solid var(--gray--border);
}

.review__section + .review__section {
  border-top: 1px solid var(--gray--border);
}

.review__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.review__attestation__body,
.review__attestation__footer {
  border: 1px solid var(--gray--border);
}

.review__attestation__body {
  border-bottom: 0;
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}

.review__attestation__footer {
  background-color: var(--gray--inactive);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.review__attestation__body .form-control--textarea {
  color: var(--text--1);
  background-color: var(--white);
  pointer-events: visible;
}

.review__card--plan .plan__card {
  border: 0;
}

.sep-document-upload {
  background-color: var(--sep-doc-upload-bg-color, var(--gray--inactive));
}

.sep-document-upload__card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}

.sep-document-upload__card__footer {
  border-top: 1px solid var(--gray--border);
}

.sep-document-upload__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.sep-document-upload__panel__body {
  background-color: var(--white);
}

.sep-document-upload__panel__header,
.sep-document-upload__panel__document {
  border-bottom: 1px solid var(--gray--border);
}

.sep-document-upload__panel {
  border-left: 1px solid var(--gray--border);
}

.subbullet--text::after,
.bullet--text::after {
  background-color: var(--black);
}

.sep-document-upload .shopping__header--content {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 48rem) {
  .sep-document-upload .shopping__header--content {
    width: 66.6666666667%;
  }
}
@media (min-width: 80rem) {
  .sep-document-upload .shopping__header--content {
    width: 50%;
  }
}

.QLEDocumentUpload__Card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}

.QLEDocumentUpload__CardSectionHeader {
  border-top: 1px solid var(--gray--border);
}

.QLEDocumentUpload__Footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.special-enrollment {
  background-image: url("https://d32ul9oyxvd2n5.cloudfront.net/assets/stripe.svg");
  background-repeat: repeat-y;
  background-size: 100%;
}

.special-enrollment__card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}

.special-enrollment__card__toggle.is-open {
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--gray--border);
}
.special-enrollment__card__toggle:hover .icon--medium {
  color: var(--primary);
}

.special-enrollment__card__documents {
  background-color: var(--gray--inactive);
  border-radius: 0.25rem;
}

.special-enrollment__footer {
  background-color: var(--white);
  box-shadow: var(--footer-shadow);
}

.special-enrollment__card__result {
  border-top: 1px solid var(--gray--border);
}

@media (min-width: 48rem) {
  .medicare__demographics__campaign .t-caption {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.medicare__demographics__splash {
  height: 7rem;
  background: url("https://zipari-uploads-dev.s3.amazonaws.com/files/49efe901-5560-4497-b67a-0cff3f1610fb") 50% 50%/cover no-repeat;
}
.medicare__demographics__splash h1,
.medicare__demographics__splash i,
.medicare__demographics__splash p {
  color: var(--white);
}
@media (min-width: 48rem) {
  .medicare__demographics__splash {
    height: 13rem;
  }
}
@media (min-width: 64rem) {
  .medicare__demographics__splash {
    height: var(--medicare-demographics-splash-height, 30rem);
  }
}
@media (min-width: 80rem) {
  .medicare__demographics__splash {
    height: var(--medicare-demographics-splash-height, 30rem);
  }
}
@media (min-width: 84rem) and (max-width: 128rem) {
  .medicare__demographics__splash {
    height: 34rem;
  }
}
@media (min-width: 128rem) and (max-width: 256rem) {
  .medicare__demographics__splash {
    height: 50rem;
  }
}

.medicare__demographics__sep,
.medicare__demographics__details,
.medicare__demographics__splash__content--mobile {
  border-bottom: 1px solid var(--gray--border);
}

.medicare__demographics__details__sep {
  margin-bottom: 1.5rem;
  background-color: var(--gray--inactive);
  border-radius: 0.25rem;
}

.medicare__demographics__coverage__card {
  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px 0 var(--gray--shadow);
}

.medicare__demographics__coverage__option.is-active .medicare__demographics__coverage__option__avatar {
  background-color: var(--primary--tint);
  border: 1px solid var(--gray--shadow);
}
.medicare__demographics__coverage__option.is-active .t-alt {
  color: var(--primary);
  font-weight: var(--heavy);
}

.medicare__demographics__coverage__option__avatar {
  background-color: var(--gray--border);
}
.medicare__demographics__coverage__option__avatar i {
  font-size: 3rem;
}
.medicare__demographics__coverage__option__avatar svg {
  fill: var(--text--2);
}

.medicare__demographics__coverage__card__header {
  background-color: var(--gray--inactive);
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
}
.medicare__demographics__coverage__card__header .icon--medium {
  color: var(--primary);
}

.medicare__demographics__coverage__card__footer .icon,
.medicare__demographics__coverage__card__footer .t-data {
  color: var(--primary);
}

.medicare__demographics__submit button {
  width: 100%;
}

.medicare__demographics__subsidy__wrapper::after {
  border-top: 1px solid var(--gray--border);
}

.shopping {
  grid-template-rows: var(--shopping-portal-grid-template-rows, 3.75rem 1fr);
  background-color: var(--white);
}

.modal__body .section--title {
  border-bottom: 1px solid var(--gray--border);
}

.shopping__header {
  padding: 2rem 0;
  margin: 0 auto;
}
@media (max-width: 47.9375rem) {
  .shopping__header {
    padding: 1rem 0;
  }
}

.enhanced-direct-enrollment {
  overflow: hidden;
}
.enhanced-direct-enrollment .form__section__table .header {
  border-bottom: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .form__section__table .header {
  border-bottom: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .summary__section:first-of-type {
  border: 0;
}
.enhanced-direct-enrollment .section-boarder {
  border-top: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .summary__callout {
  border-bottom: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .summary__callout .summary__callout__left {
  border-right: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .summary__title {
  border-bottom: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .details--wrapper {
  border-top: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .shopping__header {
  background-color: var(--primary--highlight);
}
.enhanced-direct-enrollment .shopping__header .t-data,
.enhanced-direct-enrollment .shopping__header .icon {
  color: var(--white);
}
.enhanced-direct-enrollment .shopping__header__toggle {
  border-right: 1px solid var(--white);
}
.enhanced-direct-enrollment .shopping__progress__detail {
  border-right: 1px solid var(--text--2);
}
.enhanced-direct-enrollment .shopping__help {
  border-left: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .shopping__intro__wrapper {
  border-bottom: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .form__section__header {
  padding: 1.5rem 1.5rem 0;
}
.enhanced-direct-enrollment .form__section__header p {
  margin-top: 0.5rem;
}
.enhanced-direct-enrollment .form__section {
  margin-bottom: 2.5rem;
  border-radius: 0.25rem;
  box-shadow: var(--card-shadow);
}
.enhanced-direct-enrollment .form__section.readonly {
  color: var(--text--2);
}
.enhanced-direct-enrollment .form__section.readonly h1,
.enhanced-direct-enrollment .form__section.readonly h2,
.enhanced-direct-enrollment .form__section.readonly h3,
.enhanced-direct-enrollment .form__section.readonly h4,
.enhanced-direct-enrollment .form__section.readonly h5,
.enhanced-direct-enrollment .form__section.readonly h6,
.enhanced-direct-enrollment .form__section.readonly p,
.enhanced-direct-enrollment .form__section.readonly label {
  color: var(--text--2);
}
.enhanced-direct-enrollment .form__section.readonly h1:focus,
.enhanced-direct-enrollment .form__section.readonly h2:focus,
.enhanced-direct-enrollment .form__section.readonly h3:focus,
.enhanced-direct-enrollment .form__section.readonly h4:focus,
.enhanced-direct-enrollment .form__section.readonly h5:focus,
.enhanced-direct-enrollment .form__section.readonly h6:focus,
.enhanced-direct-enrollment .form__section.readonly p:focus,
.enhanced-direct-enrollment .form__section.readonly label:focus {
  outline: none;
}
.enhanced-direct-enrollment .form__section.readonly .form-control--checkbox {
  color: var(--white);
  background-color: var(--text--2);
  border-color: var(--transparent);
}
.enhanced-direct-enrollment .form__section.readonly .t-link {
  color: var(--gray--shadow);
}
.enhanced-direct-enrollment .form__section.readonly .toggle .toggle__option {
  background-color: var(--transparent);
}
.enhanced-direct-enrollment .form__section.readonly .toggle .toggle__option:focus, .enhanced-direct-enrollment .form__section.readonly .toggle .toggle__option:hover {
  border-color: var(--gray--border);
}
.enhanced-direct-enrollment .form__section.readonly .toggle .toggle__option.is-active {
  border-color: var(--gray--border);
}
.enhanced-direct-enrollment .form__section.readonly .toggle .toggle__option p {
  color: var(--gray--shadow);
}
.enhanced-direct-enrollment .form-control[disabled].form-control--checkbox:checked::after {
  background-color: transparent;
}
.enhanced-direct-enrollment .form__section__footer {
  border-top: 1px solid var(--gray--border);
}
.enhanced-direct-enrollment .form__section__body {
  padding: 2rem 1.5rem;
}
.enhanced-direct-enrollment .form-group__action {
  width: 10rem;
  margin-left: auto;
}
.enhanced-direct-enrollment .form__section__redirect__message .t-error {
  margin-bottom: 0.5rem;
}
.enhanced-direct-enrollment .assistor__section h4 {
  border-bottom: 1px solid var(--gray--border);
}

.broker-portal__outlet {
  margin-top: var(--broker-portal__outlet-margin-top, 3.75rem);
}
@media (min-width: 48rem) {
  .broker-portal__outlet {
    margin-top: var(--broker-portal__outlet-margin-top, 2rem);
  }
}

section#main {
  outline: none;
}

body ul {
  list-style: none;
}

.page__content--desktop {
  display: none;
}
@media (min-width: 80rem) {
  .page__content--desktop {
    display: block;
  }
}

.page__content--tablet {
  display: none;
}
@media (min-width: 48rem) {
  .page__content--tablet {
    display: block;
  }
}

@media (min-width: 48rem) {
  .page__content--mobile {
    display: none;
  }
}